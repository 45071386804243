import React from "react";
import { connect } from "react-redux";
import Form from "../Common/Form";
import Select from "../Common/Select";
import Sidebar from "../Sidebar/Sidebar";
import { getLesson } from "../../Firebase/Firebase";
import { exercise_types, lesson_levels } from "../Util/data";
import Spinner from "./../Common/Spinner";
import MultipleChoiceVisual from "./ExerciseTypes/MultipleChoiceVisual";
import MultipleChoiceAudio from "./ExerciseTypes/MultipleChoiceAudio";
import WordOrderVisual from "./ExerciseTypes/WordOrderVisual";
import TypeTheAnswerVisual from "./ExerciseTypes/TypeTheAnswerVisual";
import TypeTheAnswerAudio from "./ExerciseTypes/TypeTheAnswerAudio";
import Matching from "./ExerciseTypes/Matching";
import Speaking from "./ExerciseTypes/Speaking";

class AddExercise extends Form {
  state = {
    loading: true,
    exercise: null,
    isEditing: false,
    multipleChoicesAnswer: [],
    lessonId: "",
    lesson_level: "",
    exercise_type: "",
    errors: {},
  };

  async componentDidMount() {
    const isEditing = this.props.location.state.data;
    const exercise = this.props.location.state.exercise;
    const lessonId = this.props.location.state.lessonId;
    const lesson = await getLesson(lessonId);

    if (isEditing) {
      this.setState({ exercise_type: exercise.exercise_type });
    }
    this.setState({
      isEditing,
      loading: false,
      exercise,
      lessonId,
      lesson_level: lesson.lesson_level,
    });
  }

  fileInputHandler = (e) => {
    this.setState({ [e.target.name]: e.target.files[0] });
  };

  exerciseTypeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  exerciseInputHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  setLoading = (value) => {
    this.setState({ loading: value });
  };
  render() {
    const { exercise_type } = this.state;
    return (
      <Sidebar>
        {this.state.loading ? (
          <Spinner />
        ) : (
          <div>
            <h2>{this.state.isEditing ? "Edit Exercise" : "Add Exercise"}</h2>
            <Select
              name="exercise_type"
              label="Exercise Type"
              onChange={this.exerciseInputHandler}
              disabled={this.state.isEditing}
              value={this.state.exercise_type}
              error={this.state.errors.exercise_type}
              data={exercise_types}
            />

            {exercise_type === "Multiple choice (Visual)" && (
              <MultipleChoiceVisual
                lessonId={this.state.lessonId}
                lesson_level={this.state.lesson_level}
                exercise_type={exercise_type}
                exercise={this.state.exercise}
                isEditing={this.state.isEditing}
                setLoading={this.setLoading}
              />
            )}

            {exercise_type === "Multiple choice (Audio)" && (
              <MultipleChoiceAudio
                lessonId={this.state.lessonId}
                lesson_level={this.state.lesson_level}
                exercise_type={exercise_type}
                exercise={this.state.exercise}
                isEditing={this.state.isEditing}
                setLoading={this.setLoading}
              />
            )}

            {exercise_type === "Word Order(Visual)" && (
              <WordOrderVisual
                lessonId={this.state.lessonId}
                lesson_level={this.state.lesson_level}
                exercise_type={exercise_type}
                exercise={this.state.exercise}
                isEditing={this.state.isEditing}
                setLoading={this.setLoading}
              />
            )}

            {exercise_type === "Type the answer(Visaul)" && (
              <TypeTheAnswerVisual
                lessonId={this.state.lessonId}
                lesson_level={this.state.lesson_level}
                exercise_type={exercise_type}
                exercise={this.state.exercise}
                isEditing={this.state.isEditing}
                setLoading={this.setLoading}
              />
            )}
            {exercise_type === "Type the answer(Audio)" && (
              <TypeTheAnswerAudio
                lessonId={this.state.lessonId}
                lesson_level={this.state.lesson_level}
                exercise_type={exercise_type}
                exercise={this.state.exercise}
                isEditing={this.state.isEditing}
                setLoading={this.setLoading}
              />
            )}
            {exercise_type === "Matching" && (
              <Matching
                lessonId={this.state.lessonId}
                lesson_level={this.state.lesson_level}
                exercise_type={exercise_type}
                exercise={this.state.exercise}
                isEditing={this.state.isEditing}
                setLoading={this.setLoading}
              />
            )}

            {exercise_type === "Speaking" && (
              <Speaking
                lessonId={this.state.lessonId}
                lesson_level={this.state.lesson_level}
                exercise_type={exercise_type}
                exercise={this.state.exercise}
                isEditing={this.state.isEditing}
                setLoading={this.setLoading}
              />
            )}
          </div>
        )}
      </Sidebar>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(AddExercise);
