import React, { Component } from "react";
import { RiAddCircleLine } from "react-icons/ri";
import { connect } from "react-redux";
import Input from "../../Common/Input";
import { ImCross } from "react-icons/im";
import { FiEdit } from "react-icons/fi";
import Modal from "../../Common/Modal";
import { withRouter } from "react-router";
import { addExercise } from "../../../Firebase/Firebase";

class Matching extends Component {
  state = {
    loading: true,
    isEditing: false,
    matchingChoicesAnswer: [],
    exercise_instruction_german: "",
    exercise_instruction_english: "",
    match_choice_text_1: "",
    match_choice_text_2: "",
    isEditingChoice: false,
    id: "",
    errors: {},
  };

  componentDidMount() {
    const isEditing = this.props.isEditing;
    const exercise = this.props.exercise;
    if (isEditing) {
      this.setState({
        exercise_instruction_english: exercise.exercise_instruction_english,
        exercise_instruction_german: exercise.exercise_instruction_german,
        match_question_text: exercise.match_question_text,
        matchingChoicesAnswer: exercise.matchingChoicesAnswer,
        isEditing,
      });
    }
  }

  exerciseTypeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  exerciseInputHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  matchingInputHandler = (e) => {
    const errors = { ...this.state.errors };
    if (e.target.value.length > 100) {
      errors[e.target.name] = "Charecter should be between 1 - 100";
      this.setState({ errors, [e.target.name]: e.target.value });
      return;
    } else {
      delete errors[e.target.name];
      this.setState({
        [e.target.name]: e.target.value,
        errors,
      });
    }
  };

  deleteChoice = (id) => {
    let matchingChoicesAnswer = [...this.state.matchingChoicesAnswer];
    matchingChoicesAnswer = matchingChoicesAnswer.filter(
      (item) => item.id !== id
    );
    this.setState({ matchingChoicesAnswer });
  };

  checkCorrectOption = () => {
    const itemFound = this.state.matchingChoicesAnswer.find(
      (item) => item.correct_choice === true
    );
    return itemFound ? true : false;
  };

  addChoice = () => {
    const {
      match_choice_text_1,
      match_choice_text_2,
      isEditingChoice,
      id,
    } = this.state;

    if (
      match_choice_text_1 === "" ||
      match_choice_text_1.length > 100 ||
      match_choice_text_1.trim().length === 0
    ) {
      return alert(
        "Choice should not be empty and must be less than the 100 charecters"
      );
    }
    if (
      match_choice_text_2 === "" ||
      match_choice_text_1.length > 100 ||
      match_choice_text_2.trim().length === 0
    ) {
      return alert(
        "Choice should not be empty and must be less than the 100 charecters"
      );
    }

    const choiceid = isEditingChoice ? id : Math.random() * 2000;

    if (match_choice_text_1 === "" || match_choice_text_2 === "") {
      alert(" Both Choices are required. ");
      return;
    }
    const matchingChoicesAnswer = [...this.state.matchingChoicesAnswer];

    if (isEditingChoice) {
      const index = matchingChoicesAnswer.findIndex(
        (item) => item.id === choiceid
      );
      matchingChoicesAnswer[index].match_choice_text_1 = match_choice_text_1;
      matchingChoicesAnswer[index].match_choice_text_2 = match_choice_text_2;
    } else {
      matchingChoicesAnswer.push({
        id: choiceid,
        match_choice_text_1,
        match_choice_text_2,
      });
    }

    this.setState({
      match_choice_text_1: "",
      match_choice_text_2: "",
      matchingChoicesAnswer,
      isEditingChoice: false,
      id: "",
    });
    alert(isEditingChoice ? "Edited successfully" : "Added successfully");
  };

  editChoice = (item) => {
    this.setState({
      match_choice_text_1: item.match_choice_text_1,
      match_choice_text_2: item.match_choice_text_2,
      isEditingChoice: true,
      id: item.id,
    });
  };

  saveExercise = async () => {
    const {
      matchingChoicesAnswer,
      exercise_instruction_german,
      exercise_instruction_english,
      isEditing,
    } = this.state;

    const instructor = this.props.user;
    if (
      exercise_instruction_german === "" ||
      exercise_instruction_german.trim().length === 0
    ) {
      alert("Instruction in German should be required");
      return;
    }
    if (
      exercise_instruction_english === "" ||
      exercise_instruction_english.trim().length === 0
    ) {
      alert("Instruction in English ashould be required");
      return;
    }

    const data = {
      lessonId: this.props.lessonId,
      lesson_level: this.props.lesson_level,
      exercise_type: this.props.exercise_type,
      instructorId: instructor.uid,
      exercise_instruction_english,
      exercise_instruction_german,
      matchingChoicesAnswer,
      order: 1,
      exerciseId: this.props.exercise ? this.props.exercise.exerciseId : null,
    };
    this.props.setLoading(true);

    try {
      if (isEditing) {
        await addExercise(data, isEditing);
        this.props.history.push(`/lessons/lesson/${this.props.lessonId}`);
      } else {
        await addExercise(data, isEditing);
        this.props.history.push(`/lessons/lesson/${this.props.lessonId}`);
      }
    } catch (error) {
      this.props.setLoading(false);
      console.log(error);
      alert(error.message);
    }
  };

  render() {
    return (
      <div>
        <Input
          name="exercise_instruction_german"
          label="Instruction (German)"
          onChange={this.exerciseInputHandler}
          value={this.state.exercise_instruction_german}
          error={this.state.errors.exercise_instruction_german}
          type="text"
          placeholder="Exercise Instruction (German)"
        />

        <Input
          name="exercise_instruction_english"
          label="Instruction (English)"
          value={this.state.exercise_instruction_english}
          onChange={this.exerciseInputHandler}
          error={this.state.errors.exercise_instruction_english}
          type="text"
          placeholder="Exercise Instruction (English)"
        />

        <div className="row">
          <div className="col-sm-3">
            <label className="font-weight-bold mt-1">Matching Choices</label>
          </div>
          <div className="col-sm-9 card">
            <button
              className="btn primary-button-outline  my-2 "
              data-toggle="modal"
              data-target="#paymentmodal"
            >
              Add Choice
              <span className="ml-2 fs-20">
                <RiAddCircleLine />
              </span>
            </button>
            <ul className="list-group mb-3">
              {this.state.matchingChoicesAnswer.map((item) => (
                <div key={item.id} className="row my-2">
                  <div className="col-5">
                    <span> {item.match_choice_text_1}</span>
                  </div>
                  <div className="col-5">
                    <span> {item.match_choice_text_2}</span>
                  </div>
                  <div className="col-1">
                    <span
                      className="pointer"
                      onClick={() => this.editChoice(item)}
                      data-toggle="modal"
                      data-target="#paymentmodal"
                    >
                      <FiEdit />
                    </span>
                  </div>
                  <div className="col-1">
                    <span
                      style={{ color: "red" }}
                      className="pointer"
                      onClick={() => this.deleteChoice(item.id)}
                    >
                      <ImCross />
                    </span>
                  </div>
                </div>
              ))}
            </ul>
          </div>
        </div>

        <button className="btn primary-button mt-3" onClick={this.saveExercise}>
          {this.state.isEditing ? "Save  Changes" : "Add Exercise"}
        </button>

        <Modal
          label={this.state.isEditingChoice ? "Edit Choice" : "Add Choice"}
          buttonLabel={
            this.state.isEditingChoice ? "Edit Choice" : "Create choice"
          }
          modal_size_class="modal-lg"
          buttonClicked={this.addChoice}
        >
          <Input
            name="match_choice_text_1"
            label="Choice"
            value={this.state.match_choice_text_1}
            error={this.state.errors.match_choice_text_1}
            onChange={this.matchingInputHandler}
            type="text"
            placeholder="Matching Choice label 1"
          />
          <Input
            name="match_choice_text_2"
            label="Choice"
            value={this.state.match_choice_text_2}
            error={this.state.errors.match_choice_text_2}
            onChange={this.matchingInputHandler}
            type="text"
            placeholder="Matching Choice label 2"
          />
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(withRouter(Matching));
