import React, { Component } from "react";
import _ from "lodash";
import { RiAddCircleLine } from "react-icons/ri";
import { Link, withRouter } from "react-router-dom";
import {
  getAdvertisements,
  deleteAdvertisement,
  changeAdvertisementStatus,
} from "../../../Firebase/Firebase";
import Spinner from "../../Common/Spinner";
import Sidebar from "../Sidebar/Sidebar";
import AdvertisementTable from "./AdvertisementTable";

class Advertisements extends Component {
  state = {
    advertisements: [],
    loading: true,
  };
  async componentDidMount() {
    try {
      const advertisements = await getAdvertisements();
      console.log(advertisements);
      this.setState({ loading: false, advertisements });
    } catch (error) {
      this.setState({ loading: false });
      alert(error.message);
    }
  }

  deleteAd = async (advertisement) => {
    const confirm = window.confirm("Are you sure you want to delete this?");
    if (confirm) {
      try {
        this.setState({ loading: true });
        const advertisements = [...this.state.advertisements];
        const updateadvertisement = advertisements.filter(
          (add) => add.advertisementId !== advertisement.advertisementId
        );
        await deleteAdvertisement(advertisement.advertisementId);
        this.setState({ advertisements: updateadvertisement, loading: false });
      } catch (error) {
        this.setState({ loading: false });
        alert(error.message);
      }
    }
  };

  updateadvertisement = (advertisement) => {
    this.props.history.push({
      pathname: "/admin/advertisements/new",
      state: { advertisement: advertisement, isEditing: true },
    });
  };
  statusHandler = async (advertisement) => {
    const response = window.confirm(
      advertisement.status
        ? "Are you sure you want to off this advertisement?"
        : "Are you sure you want to on this advertisement?"
    );
    if (response) {
      const status = !advertisement.status;
      try {
        this.setState({ loading: true });
        await changeAdvertisementStatus(status, advertisement.advertisementId);
        const advertisements = [...this.state.advertisements];
        const updatedAdvertisements = [];
        advertisements.forEach((item) => {
          if (item.advertisementId === advertisement.advertisementId) {
            item.status = !item.status;
          }
          updatedAdvertisements.push(item);
        });
        this.setState({
          advertisements: updatedAdvertisements,
          loading: false,
        });
      } catch (error) {
        this.setState({ loading: false });
        alert(error.message);
      }
    }
  };

  render() {
    return (
      <Sidebar>
        {this.state.loading ? (
          <Spinner />
        ) : (
          <div>
            <h2>Advertisement</h2>
            <Link
              className="btn btn-block primary-button-outline  mb-3"
              to={{
                pathname: "/admin/advertisements/new",
                state: { isEditing: false },
              }}
            >
              Create New Advertisement
              <span className="ml-2 fs-20">
                <RiAddCircleLine />
              </span>
            </Link>
            <AdvertisementTable
              data={this.state.advertisements}
              deleteAd={this.deleteAd}
              updateadvertisement={this.updateadvertisement}
              statusChangeHandler={this.statusHandler}
            />
          </div>
        )}
      </Sidebar>
    );
  }
}

export default withRouter(Advertisements);
