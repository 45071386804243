import React from "react";
import { MdDelete } from "react-icons/md";
import { withRouter } from "react-router-dom";

const AdvertisementTable = (props) => {
  console.log(props.data);
  return (
    <table className="responsive__table">
      <thead>
        <tr>
          <th scope="col">Title</th>
          <th scope="col">Lessons</th>
          <th scope="col">Reach</th>
          <th scope="col">Clicks</th>
          <th scope="col"></th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        {props.data.map((ad) => (
          <tr key={ad.advertisementId}>
            <th scope="row" data-label="Title">
              {ad.title.length > 100
                ? ad.title.substr(0, 100) + "..."
                : ad.title}
            </th>
            <td data-label="Lesson Codes">
              {ad.lessonCodes.map((item) => (
                <span>{item.lesson_code}, </span>
              ))}
            </td>
            <td data-label="Reach">{ad.reach}</td>
            <td data-label="Clicks">{ad.clicks}</td>
            <td data-label="Status">
              <div className="custom-control custom-switch">
                <input
                  onChange={() => props.statusChangeHandler(ad)}
                  checked={ad.status}
                  type="checkbox"
                  className="custom-control-input"
                  id={ad.advertisementId}
                />
                <label
                  className="custom-control-label"
                  htmlFor={ad.advertisementId}
                >
                  off/on
                </label>
              </div>
            </td>
            <td data-label="Update">
              <span
                onClick={() =>
                  props.history.push({
                    pathname: "/admin/advertisements/new",
                    state: { isEditing: true, advertisement: ad },
                  })
                }
                className="btn primary-button pointer"
              >
                Update
              </span>
            </td>
            <td data-label="Delete">
              <span
                onClick={() => props.deleteAd(ad)}
                className="fs-20 pointer text-danger"
              >
                <MdDelete />
              </span>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default withRouter(AdvertisementTable);
