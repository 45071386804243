import React, { useState } from "react";
import { MdDelete } from "react-icons/md";
import { BsChevronDoubleDown } from "react-icons/bs";
import { BiSort } from "react-icons/bi";

const LessonsTable = (props) => {
  const [activeLessons, setActiveLessons] = useState(5);

  return (
    <div>
      <table class="responsive__table">
        <thead>
          <tr>
            <th scope="col" onClick={() => props.raiseSort("name_german")}>
              Name (German){" "}
              <span style={{ fontSize: "22px", cursor: "pointer" }}>
                <BiSort />
              </span>
            </th>
            <th scope="col" onClick={() => props.raiseSort("name_english")}>
              Name (English){" "}
              <span style={{ fontSize: "22px", cursor: "pointer" }}>
                <BiSort />
              </span>
            </th>
            <th scope="col" style={{ whiteSpace: "nowrap" }}>
              Lesson Code
            </th>
            <th
              scope="col"
              onClick={() => props.raiseSort("lesson_level")}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              Level{" "}
              <span style={{ fontSize: "22px" }}>
                <BiSort />
              </span>
            </th>
            <th scope="col"></th>
            <th scope="col"></th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          {props.data.length > 0 ? (
            props.data.slice(0, activeLessons).map((lesson) => (
              <tr key={lesson.lessonId}>
                <th scope="row" data-label="Name(German)">
                  {lesson.name_german}
                </th>
                <td data-label="Name(English)">{lesson.name_english}</td>
                <td data-label="Lesson Code">{lesson.lesson_code}</td>
                <td data-label="Lesson Level">{lesson.lesson_level}</td>
                <td data-label="Add">
                  <span
                    onClick={() => props.lessonClicked(lesson)}
                    className="btn btn-success pointer"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    Add Exercise
                  </span>
                </td>
                <td data-label="Delete">
                  <span
                    onClick={() => props.deleteLesson(lesson)}
                    className="fs-20 pointer text-danger"
                  >
                    <MdDelete />
                  </span>
                </td>
                <td data-label="Update">
                  <span
                    onClick={() => props.updateLesson(lesson)}
                    className="btn primary-button pointer"
                  >
                    Update
                  </span>
                </td>
              </tr>
            ))
          ) : (
            <p className="mt-2">There is no lessons added yet.</p>
          )}
        </tbody>
      </table>

      {props.data.length > activeLessons && (
        <div className="text-center">
          <button
            className="btn primary-button"
            onClick={() => setActiveLessons(activeLessons + 5)}
          >
            <BsChevronDoubleDown />
          </button>
        </div>
      )}
    </div>
  );
};

export default LessonsTable;
