import React, { Component } from "react";
import { connect } from "react-redux";
import Input from "../../Common/Input";
import { addExercise } from "../../../Firebase/Firebase";
import { withRouter } from "react-router";

class WordOrderVisual extends Component {
  state = {
    loading: true,
    isEditing: false,
    exercise_instruction_german: "",
    exercise_instruction_english: "",
    correct_order: "",
    incorrect_order: "",
    correct_order_variations: [],
    variations: 0,
    errors: {},
  };

  componentDidMount() {
    const isEditing = this.props.isEditing;
    const exercise = this.props.exercise;
    if (isEditing) {
      this.setState({
        exercise_instruction_english: exercise.exercise_instruction_english,
        exercise_instruction_german: exercise.exercise_instruction_german,
        correct_order: exercise.correct_order,
        incorrect_order: exercise.incorrect_order,
        variations: exercise.variations,
        correct_order_variations: exercise.correct_order_variations,
        isEditing,
      });
    }
  }

  exerciseInputHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  increaseVariation = () => {
    this.setState({
      variations: this.state.variations + 1,
    });
  };

  addVariationChangeHandler = (i, e) => {
    const list = [...this.state.correct_order_variations];
    list[i] = e.target.value;
    this.setState({ correct_order_variations: list });
  };

  saveExercise = async () => {
    const {
      exercise_instruction_german,
      exercise_instruction_english,
      isEditing,
      correct_order,
      incorrect_order,
      correct_order_variations,
      variations,
    } = this.state;

    const instructor = this.props.user;

    if (
      exercise_instruction_german === "" ||
      exercise_instruction_german.trim().length === 0
    ) {
      alert("Instruction in German should be required");
      return;
    }
    if (
      exercise_instruction_english === "" ||
      exercise_instruction_english.trim().length === 0
    ) {
      alert("Instruction in English ashould be required");
      return;
    }

    if (correct_order === "" || correct_order.trim().length === 0) {
      alert("Correct Order are required");
      return;
    }
    if (incorrect_order === "" || incorrect_order.trim().length === 0) {
      alert("Incorrect Order are required");
      return;
    }

    const data = {
      lessonId: this.props.lessonId,
      lesson_level: this.props.lesson_level,
      exercise_type: this.props.exercise_type,
      instructorId: instructor.uid,
      exercise_instruction_english,
      exercise_instruction_german,
      correct_order,
      incorrect_order,
      variations,
      correct_order_variations,
      exerciseId: this.props.exercise ? this.props.exercise.exerciseId : null,
      order: 4,
    };

    this.props.setLoading(true);
    try {
      await addExercise(data, isEditing);
      this.props.history.push(`/lessons/lesson/${this.props.lessonId}`);
    } catch (error) {
      this.props.setLoading(false);
      alert(error.message);
    }
  };

  render() {
    return (
      <div>
        <Input
          name="exercise_instruction_german"
          label="Instruction (German)"
          onChange={this.exerciseInputHandler}
          value={this.state.exercise_instruction_german}
          error={this.state.errors.exercise_instruction_german}
          type="text"
          placeholder="Exercise Instruction (German)"
        />

        <Input
          name="exercise_instruction_english"
          label="Instruction (English)"
          value={this.state.exercise_instruction_english}
          onChange={this.exerciseInputHandler}
          error={this.state.errors.exercise_instruction_english}
          type="text"
          placeholder="Exercise Instruction (English)"
        />

        <Input
          name="incorrect_order"
          label="InCorrect Order"
          value={this.state.incorrect_order}
          onChange={this.exerciseInputHandler}
          error={this.state.errors.incorrect_order}
          type="text"
          placeholder="Incorrect Order Text"
        />

        <Input
          name="correct_order"
          label="Correct Order"
          onChange={this.exerciseInputHandler}
          value={this.state.correct_order}
          error={this.state.errors.correct_order}
          type="text"
          placeholder="Correct Order Text"
        />

        {[...Array(this.state.variations)].map((x, i) => (
          <Input
            key={i}
            label="Correct Order (Variation)"
            onChange={(e) => this.addVariationChangeHandler(i, e)}
            value={this.state.correct_order_variations[i]}
            type="text"
            placeholder="Correct Order Variation Text"
          />
        ))}

        {/* <button
          className="btn vairation-button"
          onClick={() => this.increaseVariation()}
        >
          Add Variation
        </button> */}

        <button className="btn primary-button mt-3" onClick={this.saveExercise}>
          {this.state.isEditing ? "Save  Changes" : "Add Exercise"}
        </button>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(withRouter(WordOrderVisual));
