import React from "react";

const Checkbox = ({ name, label, error, ...rest }) => {
  return (
    <div className="form-group row">
      <label htmlFor={name} className="col-sm-3 font-weight-bold mt-1">
        {label}
      </label>
      <div className="col-sm-9">
        <div class="form-check">
          <input type="checkbox"  class="form-check-input" name={name} id={name} {...rest} />
          <label class="form-check-label" for={name}>
            Admin
          </label>
        </div>
        {error && <span className="error-message">{error}</span>}
      </div>
    </div>
  );
};

export default Checkbox;
