import React, { Fragment } from "react";
import Sidebar from "../Sidebar/Sidebar";
import Placeholder from "../../../assets/placeholderimage.png";
import TextArea from "../../Common/TextArea";
import {
  getLessonSetting,
  submitLessonSetting,
} from "../../../Firebase/Firebase";
import Spinner from "../../Common/Spinner";
import { withRouter } from "react-router";

class CompleteLessonSettings extends React.Component {
  state = {
    loading: true,
    image_url: null,
    image: null,
    text: "",
  };

  async componentDidMount() {
    try {
      const data = await getLessonSetting();
      if (data) {
        this.setState({
          image_url: data.image_url,
          text: data.text,
        });
      }
      this.setState({ loading: false });
    } catch (error) {
      this.setState({ loading: false });
      alert(error.message);
    }
  }

  getWidthAndHeight = (e) =>
    new Promise((resolve) => {
      var img = document.createElement("img");
      img.onload = function () {
        resolve({ width: this.width, height: this.height });
      };
      var reader = new FileReader();
      reader.onloadend = function (ended) {
        img.src = ended.target.result;
      };
      reader.readAsDataURL(e.target.files[0]);
    });

  fileHandler = async (e) => {
    if (e.target.files && e.target.files[0]) {
      const obj = await this.getWidthAndHeight(e);
      console.log(obj);
      if (obj.width === 1640 && obj.height === 615) {
        this.setState({ [e.target.name]: e.target.files[0] });
      } else {
        alert("Dimensions must be 1640px x 615px");
      }
    }
  };

  setFiles = (file) => {
    console.log(file);
  };

  inputHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  submitForm = async () => {
    const { text, image, image_url } = this.state;
    try {
      this.setState({ loading: true });
      await submitLessonSetting(image, image_url, text);
      this.setState({ loading: false });
      alert("Added Successfully");
    } catch (error) {
      this.setState({ loading: false });
      alert(error.message);
    }
  };
  render() {
    return (
      <Sidebar>
        {this.state.loading ? (
          <Spinner />
        ) : (
          <Fragment>
            <div className="d-flex justify-content-between mt-2 mb-5">
              <span
                className="pointer"
                onClick={() => this.props.history.push("/admin/lessons")}
              >
                <i className="fas fa-arrow-circle-left fa-2x" />
              </span>
              <h2>Lesson Settings</h2>
            </div>
            <div className="row">
              <div className="col-sm-2"> </div>

              <div className="col-sm-10">
                <p style={{ color: "red", fontSize: "13px" }}>
                  Note: Ideal image size is 1640px x 615px
                </p>
                <label htmlFor="image-1" className="pointer">
                  <img
                    src={
                      this.state.image
                        ? URL.createObjectURL(this.state.image)
                        : this.state.image_url
                        ? this.state.image_url
                        : Placeholder
                    }
                    height="300px"
                    width="100%"
                  />
                </label>
                <input
                  type="file"
                  style={{ visibility: "hidden", display: "none" }}
                  id="image-1"
                  name="image"
                  onChange={this.fileHandler}
                />
              </div>
            </div>

            <TextArea
              label="Text"
              onChange={this.inputHandler}
              name="text"
              value={this.state.text}
              placeholder="Enter Text... "
            />
            <div className="row">
              <div className="col-sm-2"> </div>

              <div className="col-sm-10">
                <button
                  className="btn primary-button px-5"
                  onClick={this.submitForm}
                >
                  Submit
                </button>
              </div>
            </div>
          </Fragment>
        )}
      </Sidebar>
    );
  }
}

export default withRouter(CompleteLessonSettings);
