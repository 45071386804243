import React from "react";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { Link } from "react-router-dom";

const instructorTable = (props) => {
  return (
    <table className="responsive__table">
      <thead>
        <tr>
          <th scope="col">Name</th>
          <th scope="col">Email</th>
          <th scope="col">Mobile</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {props.data.length > 0 ? (
          props.data.map((instructor) => (
            <tr key={instructor.uid}>
              <th scope="row" data-label="Name">
                {instructor.name}
              </th>
              <td data-label="Email">
                <span style={{ whiteSpace: "pre-wrap" }}>
                  {instructor.email}
                </span>
              </td>
              <td data-label="Phone"> {instructor.mobile_phone}</td>
              <td data-label="Status">
                <div className="custom-control custom-switch">
                  <input
                    onChange={() => props.statusChangeHandler(instructor)}
                    checked={instructor.status}
                    type="checkbox"
                    className="custom-control-input"
                    id={instructor.uid}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor={instructor.uid}
                  >
                    Off/On
                  </label>
                </div>
              </td>
              <td data-label="Update">
                <Link
                  to={{
                    pathname: "/admin/instructors/new",
                    state: { isEditing: true, instructor },
                  }}
                >
                  <span style={{ fontSize: "20px" }}>
                    <FaEdit />
                  </span>
                </Link>
              </td>
              <td data-label="Delete">
                <span
                  onClick={() => props.deleteInstructor(instructor)}
                  className="fs-20 pointer text-danger"
                >
                  <MdDelete />
                </span>
              </td>
            </tr>
          ))
        ) : (
          <p className="mt-2">There is no instructor added yet.</p>
        )}
      </tbody>
    </table>
  );
};

export default instructorTable;
