import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import Input from "../../Common/Input2";
import Form from "../../Common/Form";
import Joi from "joi";
import Spinner from "../../Common/Spinner";
import Placeholder from "../../../assets/placeholderimage.png";
import {
  createAdvertisement,
  getLessons,
  updateAdvertisement,
} from "../../../Firebase/Firebase";
import Multiselect from "multiselect-react-dropdown";
import TextArea from "../../Common/TextArea";

class NewAdvertiment extends Form {
  state = {
    image_1: null,
    image_2: null,
    image_3: null,
    ad_image_url_1: null,
    ad_image_url_2: null,
    ad_image_url_3: null,
    lessons: [],
    data: {
      title: "",
      text: "",
      call_to_action: "",
      link: "",
      lessonCodes: [],
    },
    loading: true,
    isEditing: false,
    errors: {},
  };

  async componentDidMount() {
    const isEditing = this.props.location.state.isEditing;
    const lessons = await getLessons();

    if (isEditing) {
      const advertisement = this.props.location.state.advertisement;
      const updatingData = { ...this.state.data };
      updatingData.title = advertisement.title;
      updatingData.text = advertisement.text;
      updatingData.link = advertisement.link;
      updatingData.call_to_action = advertisement.call_to_action;
      updatingData.lessonCodes = advertisement.lessonCodes;

      this.setState({
        data: updatingData,
        isEditing,
        ad_image_url_1: advertisement.ad_image_url_1,
        ad_image_url_2: advertisement.ad_image_url_2,
        ad_image_url_3: advertisement.ad_image_url_3,
      });
    }

    this.setState({ lessons, loading: false });
  }

  objectSchema = {
    link: Joi.string().required().label("Link"),
    text: Joi.string().required().label("Text"),
    call_to_action: Joi.string().max(40).required().label("Action"),
    title: Joi.string().max(100).required().label("Title"),
    lessonCodes: Joi.array().min(1).label("Lesson Codes"),
  };

  getWidthAndHeight = (e) =>
    new Promise((resolve) => {
      var img = document.createElement("img");
      img.onload = function () {
        resolve({ width: this.width, height: this.height });
      };
      var reader = new FileReader();
      reader.onloadend = function (ended) {
        img.src = ended.target.result;
      };
      reader.readAsDataURL(e.target.files[0]);
    });

  fileHandler = async (e) => {
    if (e.target.files && e.target.files[0]) {
      const obj = await this.getWidthAndHeight(e);
      if (obj.width === 1640 && obj.height === 615) {
        this.setState({ [e.target.name]: e.target.files[0] });
      } else {
        alert("Dimensions must be 1640px x 615px");
      }
    }
  };

  doSubmit = async () => {
    const advertisement = this.props.location.state.advertisement;

    try {
      if (this.state.isEditing) {
        await createAdvertisement(
          this.state.data.title,
          this.state.data.text,
          this.state.data.call_to_action,
          this.state.data.link,
          this.state.data.lessonCodes,
          this.state.ad_image_url_1,
          this.state.ad_image_url_2,
          this.state.ad_image_url_3,
          this.state.image_1,
          this.state.image_2,
          this.state.image_3,
          this.state.isEditing,
          advertisement.clicks,
          advertisement.reach,
          advertisement.status,
          advertisement.advertisementId
        );
      } else {
        if (
          this.state.image_1 === null &&
          this.state.image_2 === null &&
          this.state.image_3 === null
        ) {
          alert("At least One Image is Required");
          return;
        }
        this.setState({ loading: true });
        await createAdvertisement(
          this.state.data.title,
          this.state.data.text,
          this.state.data.call_to_action,
          this.state.data.link,
          this.state.data.lessonCodes,
          this.state.ad_image_url_1,
          this.state.ad_image_url_2,
          this.state.ad_image_url_3,
          this.state.image_1,
          this.state.image_2,
          this.state.image_3,
          this.state.isEditing
        );
      }
      this.props.history.push("/admin/advertisements");
      this.setState({
        loading: false,
      });
    } catch (error) {
      console.log(error);
      this.setState({
        loading: false,
      });
      alert(error.message);
    }
  };

  onSelect = (selectedList) => {
    const data = { ...this.state.data };
    const errors = { ...this.state.errors };
    if (errors.lessonCodes) {
      delete errors["lessonCodes"];
    }
    data.lessonCodes = selectedList;

    this.setState({ data, errors });
  };

  onRemove = (selectedList) => {
    const data = { ...this.state.data };
    data.lessonCodes = selectedList;
  };

  render() {
    return (
      <Sidebar>
        {this.state.loading ? (
          <Spinner />
        ) : (
          <div>
            <h1 className="mb-4">
              {this.state.isEditing
                ? "Edit Advertisement"
                : "Create New Advertisement"}
            </h1>
            <p style={{ color: "red", fontSize: "12px" }}>
              Note: Ideal image size is 1640px x 615px
            </p>
            <div className="row mb-5">
              <div className="col-sm-4">
                <label htmlFor="image-1" className="pointer">
                  <img
                    src={
                      this.state.image_1
                        ? URL.createObjectURL(this.state.image_1)
                        : this.state.ad_image_url_1
                        ? this.state.ad_image_url_1
                        : Placeholder
                    }
                    height="150px"
                    width="100%"
                  />
                </label>
                <input
                  type="file"
                  style={{ visibility: "hidden", display: "none" }}
                  id="image-1"
                  name="image_1"
                  onChange={this.fileHandler}
                />
              </div>
              <div className="col-sm-4">
                <label htmlFor="image-2" className="pointer">
                  <img
                    src={
                      this.state.image_2
                        ? URL.createObjectURL(this.state.image_2)
                        : this.state.ad_image_url_2
                        ? this.state.ad_image_url_2
                        : Placeholder
                    }
                    height="150px"
                    width="100%"
                  />
                </label>
                <input
                  type="file"
                  style={{ visibility: "hidden", display: "none" }}
                  id="image-2"
                  name="image_2"
                  onChange={this.fileHandler}
                />
              </div>
              <div className="col-sm-4">
                <label htmlFor="image-3" className="pointer">
                  <img
                    src={
                      this.state.image_3
                        ? URL.createObjectURL(this.state.image_3)
                        : this.state.ad_image_url_3
                        ? this.state.ad_image_url_3
                        : Placeholder
                    }
                    height="150px"
                    width="100%"
                  />
                </label>
                <input
                  type="file"
                  style={{ visibility: "hidden", display: "none" }}
                  id="image-3"
                  name="image_3"
                  onChange={this.fileHandler}
                />
              </div>
            </div>
            <Input
              name="title"
              label="Title"
              onChange={this.inputHandler}
              error={this.state.errors.title}
              value={this.state.data.title}
              type="text"
              placeholder="Enter Title of Advertisement"
            />
            <TextArea
              name="text"
              label="Text"
              onChange={this.inputHandler}
              error={this.state.errors.text}
              value={this.state.data.text}
              type="text"
              placeholder="Enter Text of Advertisement"
            />

            <div className="form-group row">
              <label
                htmlFor="lesson_codes"
                className="col-sm-2 font-weight-bold mt-1"
              >
                Appear After Lesson(s)
              </label>
              <div className="col-sm-10">
                <Multiselect
                  options={this.state.lessons} // Options to display in the dropdown
                  selectedValues={this.state.data.lessonCodes} // Preselected value to persist in dropdown
                  onSelect={this.onSelect} // Function will trigger on select event
                  onRemove={this.onRemove} // Function will trigger on remove event
                  displayValue="lesson_code" // Property name to display in the dropdown options
                />
                {this.state.errors.lessonCodes && (
                  <span className="error-message">
                    {this.state.errors.lessonCodes}
                  </span>
                )}
              </div>
            </div>

            <Input
              name="call_to_action"
              label="Call To Action "
              onChange={this.inputHandler}
              error={this.state.errors.call_to_action}
              value={this.state.data.call_to_action}
              type="text"
              placeholder="Enter Action by clicking Advertisement"
            />
            <Input
              name="link"
              label="Link"
              onChange={this.inputHandler}
              error={this.state.errors.link}
              value={this.state.data.link}
              type="text"
              placeholder="Enter Link of Advertisement"
            />

            <button
              onClick={this.submitForm}
              className="btn primary-button px-5 mt-3"
            >
              {this.state.isEditing ? "Save changes" : "Submit"}
            </button>
          </div>
        )}
      </Sidebar>
    );
  }
}

export default NewAdvertiment;
