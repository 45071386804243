import firebase from "firebase";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAfTVtcUfH7vUh4xgIs3aviaF3th_FWD3M",
  authDomain: "speakeasy-95724.firebaseapp.com",
  projectId: "speakeasy-95724",
  storageBucket: "speakeasy-95724.appspot.com",
  messagingSenderId: "104675072679",
  appId: "1:104675072679:web:1ae2df4ce654ea2f8b5982",
  measurementId: "G-7EXSHXHM8F",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const storageRef = firebase.storage();
