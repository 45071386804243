import { GET_CURRENT_USER, LOGOUT_USER, INVALID_USER } from "./types";
import { createUserInDb, getCurrentUser } from "../Firebase/Firebase";

export const getUser = (email, password, login) => async (dispatch) => {
  const user = await getCurrentUser(email, password, login);
  if (user) {
    dispatch({
      type: GET_CURRENT_USER,
      payload: user,
    });
  } else {
    dispatch({
      type: INVALID_USER,
      payload: {},
    });
  }
};

export const updateUserLogin = (user) => async (dispatch) => {
  dispatch({
    type: GET_CURRENT_USER,
    payload: user,
  });
};

export const logoutUser = () => async (dispatch) => {
  sessionStorage.removeItem("instructor");
  dispatch({
    type: LOGOUT_USER,
    payload: null,
  });
};

export const createUser = (
  name,
  phone,
  address,
  email,
  password,
  status,
  created_on,
  last_login
) => async (dispatch) => {
  const user = await createUserInDb(
    name,
    phone,
    address,
    email,
    password,
    status,
    created_on,
    last_login
  );

  dispatch({
    type: GET_CURRENT_USER,
    payload: user,
  });
};
