import { GET_CURRENT_USER, LOGOUT_USER, INVALID_USER } from "../Actions/types";

const intialState = {
  isAuthenticated: false,
  user: null,
};

export default function (state = intialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: true,
        user: payload,
      };
    case INVALID_USER:
      return {
        ...state,
        isAuthenticated: false,
        user: payload,
      };
    case LOGOUT_USER:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    default:
      return state;
  }
}
