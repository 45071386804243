import React, { Component } from "react";
import NavItem from "./NavItem";
import { FaGraduationCap } from "react-icons/fa";
import { BiUserCircle } from "react-icons/bi";
import { FiLogOut } from "react-icons/fi";
import { logoutUser } from "./../../Actions/Auth";
import { connect } from "react-redux";
import "./Sidebar.css";

class Sidebar extends Component {
  state = { active: false };

  componentDidMount() {
    const expanded = localStorage.getItem("expanded");
    if (expanded) {
      expanded === "true"
        ? this.setState({ active: true })
        : this.setState({ active: false });
    }
  }
  logout = async () => {
    const logout = window.confirm("Are you sure you want to logout?");
    if (logout) {
      await this.props.logoutUser();
    }
  };

  handleActive = () => {
    this.setState({
      active: !this.state.active,
    });
    localStorage.setItem("expanded", !this.state.active);
  };

  render() {
    return (
      <div className={this.state.active ? "wrapper active" : "wrapper"}>
        <div className="main_body">
          <div className="sidebar_menu">
            <div className="inner__sidebar_menu">
              <ul>
                <div className="main_logo">
                  <h4>SpeakEasy</h4>
                </div>
                <NavItem
                  path="/lessons"
                  Icon={FaGraduationCap}
                  label="Lessons"
                />

                {/* <NavItem path="/profile" Icon={BiUserCircle} label="Profile" /> */}

                <li className="pointer">
                  <div onClick={this.logout}>
                    <span className="icon">
                      <FiLogOut />
                    </span>
                    <span className="list">Logout</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div className="sidebar-container">{this.props.children}</div>
        </div>
      </div>
    );
  }
}

export default connect(null, { logoutUser })(Sidebar);
