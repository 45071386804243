import "./App.css";
import Store from "./store";
import { Provider } from "react-redux";
import Routiing from "./components/Routing/Routing";

function App() {
  return (
    <Provider store={Store}>
      <Routiing />
    </Provider>
  );
}

export default App;
