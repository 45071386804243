import React from "react";

const Select = ({ name, label, data, error, ...rest }) => {
  return (
    <div className="form-group row">
      <label
        htmlFor={name}
        className="col-sm-3 col-form-label font-weight-bold"
      >
        {label}
      </label>
      <div className="col-sm-9">
        <select name={name} {...rest} className="form-control">
          <option value="">Select {label}</option>
          {data.map((e, i) => (
            <option key={i} value={e.id}>
              {e.name}
            </option>
          ))}
        </select>
        {error && <span className="error-message">{error}</span>}
      </div>
    </div>
  );
};

export default Select;
