import React, { Component } from "react";
import Sidebar from "../Sidebar/Sidebar";
import Joi from "joi";
import Input from "../Common/Input";
import Spinner from "../Common/Spinner";
// import Modal from "../Common/Modal";
import profileImage from "../../assets/placeholderimage.png";
import { editInstructor } from "../../Firebase/Firebase";
import { FaEdit } from "react-icons/fa";
import "./Profile.css";

class Profile extends Component {
  state = {
    loading: false,
    data: {
      id: "",
      image: null,
      src: null,
      email: "",
      name: "",
      address: "",
      mobile_phone: "",
    },
    errors: {},
  };
  async componentDidMount() {
    const vendor = JSON.parse(localStorage.getItem("instructor"));
    if (vendor !== "undefined") {
      const account = { ...this.state.data };
      account.id = vendor.uid;
      account.src = vendor.profile_image;
      account.name = vendor.name;
      account.email = vendor.email;
      account.address = vendor.address;
      account.mobile_phone = vendor.mobile_phone;
      this.setState({ data: account });
    }
  }

  fileInputHandler = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        const account = { ...this.state.data };
        account.src = reader.result;
        account.image = e.target.files[0];
        this.setState({ data: account });
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  vendorSchema = {
    name: Joi.string().required(),
    address: Joi.string().required(),
    mobile_phone: Joi.number().required(),
    email: Joi.string().required(),
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const propertySchema = { [name]: this.vendorSchema[name] };
    const { error } = Joi.object(propertySchema).validate(obj);
    return error ? error.details[0].message : null;
  };
  inputHandler = (e) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(e.target);
    if (errorMessage) {
      errors[e.target.name] = errorMessage;
    } else {
      delete errors[e.target.name];
    }
    let account = { ...this.state.data };
    account[e.target.name] = e.target.value;
    this.setState({
      data: account,
      errors,
    });
  };

  validate = () => {
    const { name, address, mobile_phone, email } = this.state.data;
    const vendorData = {
      name,
      address,
      mobile_phone,
      email,
    };
    const result = Joi.object(this.vendorSchema).validate(vendorData, {
      abortEarly: false,
    });
    if (!result.error) return null;
    const errors = {};
    for (let item of result.error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  editProfile = async (e) => {
    e.preventDefault();
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;
    if (this.state.data.image === null && !this.state.data.src) {
      alert("Please select an image.");
    } else {
      const { id, src, image, name, address, mobile_phone } = this.state.data;
      try {
        this.setState({ loading: true });
        await editInstructor(id, src, image, name, address, mobile_phone);
        alert("Successfully Updated!!");
        this.setState({
          loading: false,
        });
      } catch (error) {
        this.setState({
          loading: false,
        });
        alert(error.message);
      }
    }
  };

  render() {
    const { data: vendor, errors } = this.state;
    return (
      <Sidebar>
        {this.state.loading ? (
          <Spinner />
        ) : (
          <div className="edit__profile_container">
            <div className="edit__profile_title">
              <h3>Edit Profile</h3>
            </div>
            <hr color="	#DCDCDC" />

            <p className="text-muted" style={{ fontSize: "13px" }}>
              Note: Whenever you change something, make sure that you click on
              save changes button.
            </p>
            <h4 className="mt-4">Basic Info</h4>
            <div className="card p-3">
              <div className="vendor__profile_img mb-3">
                <div className="image-upload">
                  <label htmlFor="file-input">
                    <FaEdit />
                  </label>
                  <input
                    id="file-input"
                    type="file"
                    name="image"
                    accept="image/*"
                    onChange={this.fileInputHandler}
                  />
                </div>
                <img src={vendor.src ? vendor.src : profileImage} alt=""></img>
              </div>
              <Input
                name="name"
                label="Vendor Name"
                onChange={this.inputHandler}
                error={errors.name}
                value={vendor.name}
                type="text"
                placeholder="Enter Vendor Name"
              />
              <Input
                name="email"
                label="Email"
                onChange={this.inputHandler}
                error={errors.email}
                value={vendor.email}
                disabled={true}
                type="email"
                placeholder="Enter Vendor Email"
              />
              <Input
                name="mobile_phone"
                label="Mobile Phone"
                onChange={this.inputHandler}
                error={errors.mobile_phone}
                value={vendor.mobile_phone}
                type="number"
                placeholder="Enter Vendor Number"
              />
              <Input
                name="address"
                label="Address"
                onChange={this.inputHandler}
                error={errors.address}
                value={vendor.address}
                type="text"
                placeholder="Enter Vendor Address"
              />
            </div>

            <div>
              <button
                className="btn btn-secondary mt-2 px-5 mb-3"
                onClick={this.editProfile}
              >
                Save Changes
              </button>
            </div>
          </div>
        )}
      </Sidebar>
    );
  }
}

export default Profile;
