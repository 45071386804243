import React, { Fragment } from "react";
import Sidebar from "../Sidebar/Sidebar";
import Placeholder from "../../../assets/placeholderimage.png";
import Input from "../../Common/Input2";
import TextArea from "../../Common/TextArea";
import { getCourse, submitCourse } from "../../../Firebase/Firebase";
import Spinner from "../../Common/Spinner";

class Courses extends React.Component {
  state = {
    loading: true,
    image_url: null,
    image: null,
    online_link_course: "",
    physical_link_course: "",
    english_description: "",
    german_description: "",
  };

  async componentDidMount() {
    try {
      const data = await getCourse();
      if (data) {
        this.setState({
          image_url: data.image_url,
          german_description: data.german_description,
          english_description: data.english_description,
          online_link_course: data.online_link_course,
          physical_link_course: data.physical_link_course,
        });
      }
      this.setState({ loading: false });
    } catch (error) {
      this.setState({ loading: false });
      alert(error.message);
    }
  }
  getWidthAndHeight = (e) =>
    new Promise((resolve) => {
      var img = document.createElement("img");
      img.onload = function () {
        resolve({ width: this.width, height: this.height });
      };
      var reader = new FileReader();
      reader.onloadend = function (ended) {
        img.src = ended.target.result;
      };
      reader.readAsDataURL(e.target.files[0]);
    });

  fileHandler = async (e) => {
    if (e.target.files && e.target.files[0]) {
      const obj = await this.getWidthAndHeight(e);
      if (obj.width === 1640 && obj.height === 615) {
        this.setState({ [e.target.name]: e.target.files[0] });
      } else {
        alert("Dimensions must be 1640px x 615px");
      }
    }
  };
  inputHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  submitForm = async () => {
    const {
      online_link_course,
      physical_link_course,
      german_description,
      english_description,
      image,
      image_url,
    } = this.state;
    try {
      this.setState({ loading: true });
      await submitCourse(
        image,
        image_url,
        online_link_course,
        physical_link_course,
        german_description,
        english_description
      );
      this.setState({ loading: false });
      alert("Added Successfully");
    } catch (error) {
      this.setState({ loading: false });
      alert(error.message);
    }
  };

  render() {
    return (
      <Sidebar>
        {this.state.loading ? (
          <Spinner />
        ) : (
          <Fragment>
            <div className="row">
              <div className="col-sm-2"> </div>

              <div className="col-sm-10">
                <h2 className="mb-4 font-weight-bold"> Courses </h2>
                <p style={{ color: "red", fontSize: "12px" }}>
                  Note: Ideal image size is 1640px x 615px
                </p>
                <label htmlFor="image-1" className="pointer">
                  <img
                    src={
                      this.state.image
                        ? URL.createObjectURL(this.state.image)
                        : this.state.image_url
                        ? this.state.image_url
                        : Placeholder
                    }
                    height="300px"
                    width="100%"
                  />
                </label>
                <input
                  type="file"
                  style={{ visibility: "hidden", display: "none" }}
                  id="image-1"
                  name="image"
                  onChange={this.fileHandler}
                />
              </div>
            </div>

            <TextArea
              label="German Description"
              onChange={this.inputHandler}
              name="german_description"
              value={this.state.german_description}
              placeholder="Enter German Description... "
            />

            <TextArea
              label="English Description"
              onChange={this.inputHandler}
              name="english_description"
              value={this.state.english_description}
              placeholder="Enter English Description... "
            />

            <Input
              label="Physical Course Link"
              onChange={this.inputHandler}
              name="physical_link_course"
              value={this.state.physical_link_course}
              placeholder="Enter the link of physical course"
            />
            <Input
              label="Online Course Link"
              onChange={this.inputHandler}
              name="online_link_course"
              value={this.state.online_link_course}
              placeholder="Enter the link of online course"
            />

            <div className="row">
              <div className="col-sm-2"> </div>

              <div className="col-sm-10">
                <button
                  className="btn primary-button px-5"
                  onClick={this.submitForm}
                >
                  Submit
                </button>
              </div>
            </div>
          </Fragment>
        )}
      </Sidebar>
    );
  }
}

export default Courses;
