export const exercise_types = [
  { id: "Matching", name: "Matching" },
  { id: "Multiple choice (Visual)", name: "Multiple choice (Visual)" },
  { id: "Multiple choice (Audio)", name: "Multiple choice (Audio)" },
  { id: "Word Order(Visual)", name: "Word Order(Visual)" },
  { id: "Type the answer(Visaul)", name: "Type the answer(Visaul)" },
  { id: "Type the answer(Audio)", name: "Type the answer(Audio)" },
  { id: "Speaking", name: "Speaking" },
];

export const lesson_levels = [
  { id: "A1.1", name: "A1.1" },
  { id: "A1.2", name: "A1.2" },
  { id: "A2.1", name: "A2.1" },
  { id: "A2.2", name: "A2.2" },
  { id: "B1.1", name: "B1.1" },
  { id: "B1.2", name: "B1.2" },
  { id: "B2.1", name: "B2.1" },
  { id: "B2.2", name: "B2.2" },
  { id: "C1.1", name: "C1.1" },
  { id: "C1.2", name: "C1.2" },
];
