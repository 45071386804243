import React, { Component } from "react";
import { RiAddCircleLine } from "react-icons/ri";
import { Link, withRouter } from "react-router-dom";
import {
  changeinstructorStatus,
  getInstructors,
  deleteInstructorFromDB,
} from "../../../Firebase/Firebase";
import Spinner from "../../Common/Spinner";
import Sidebar from "../Sidebar/Sidebar";
import LessonsTable from "./InstructorTable";

// desc asc
class Instructors extends Component {
  state = {
    instructors: [],
    loading: true,
  };
  async componentDidMount() {
    try {
      const instructors = await getInstructors();
      this.setState({ loading: false, instructors });
    } catch (error) {
      this.setState({ loading: false });
      alert(error.message);
    }
  }

  deleteInstructor = async (instructor) => {
    try {
      this.setState({ loading: true });
      const instructors = [...this.state.instructors];
      const filterInstructors = instructors.filter(
        (item) => item.uid !== instructor.uid
      );
      await deleteInstructorFromDB(instructor);
      this.setState({ instructors: filterInstructors, loading: false });
    } catch (error) {
      this.setState({ loading: false });
      alert(error.message);
    }
  };

  statusHandler = async (instructor) => {
    const response = window.confirm(
      !instructor.status
        ? "Are you sure you want to Verify the instructor?"
        : "Are you sure you want to blocked the instructor?"
    );
    if (response) {
      try {
        this.setState({ loading: true });
        await changeinstructorStatus(!instructor.status, instructor.uid);
        const instructors = [...this.state.instructors];
        const updatedinstructors = [];
        instructors.forEach((item) => {
          if (item.uid === instructor.uid) {
            item.status = !item.status;
          }
          updatedinstructors.push(item);
        });
        this.setState({
          instructors: updatedinstructors,
          loading: false,
        });
      } catch (error) {
        this.setState({ loading: false });
        alert(error.message);
      }
    }
  };

  render() {
    return (
      <Sidebar>
        {this.state.loading ? (
          <Spinner />
        ) : (
          <div>
            <h2>Instructors</h2>
            <Link
              className="btn btn-block primary-button-outline  mb-2"
              to={{
                pathname: "/admin/instructors/new",
                state: { isEditing: false },
              }}
            >
              Create New Instructor
              <span className="ml-2 fs-20">
                <RiAddCircleLine />
              </span>
            </Link>
            <LessonsTable
              data={this.state.instructors}
              statusChangeHandler={this.statusHandler}
              deleteInstructor={this.deleteInstructor}
            />
          </div>
        )}
      </Sidebar>
    );
  }
}

export default withRouter(Instructors);
