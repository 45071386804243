import React, { Component } from "react";
import _ from "lodash";
import { RiAddCircleLine } from "react-icons/ri";
import { Link, withRouter } from "react-router-dom";
import { getLessons } from "../../Firebase/Firebase";
import Spinner from "../Common/Spinner";
import Sidebar from "../Sidebar/Sidebar";
import LessonsTable from "./LessonsTable";
import { deleteLessonInDb } from "../../Firebase/Firebase";

// desc asc
class Lessons extends Component {
  state = {
    lessons: [],
    loading: true,
    sortColoumn: { path: "lesson_level", order: "asc" },
  };
  async componentDidMount() {
    try {
      const lessons = await getLessons();
      this.setState({ loading: false, lessons });
    } catch (error) {
      this.setState({ loading: false });
      alert(error.message);
    }
  }
  lessonClickHandler = (lesson) => {
    this.props.history.push({
      pathname: `/lessons/lesson/${lesson.lessonId}`,
    });
  };
  deleteLesson = async (lesson) => {
    const confirm = window.confirm("Are you sure you want to delete this?");
    if (confirm) {
      try {
        this.setState({ loading: true });
        const lessons = [...this.state.lessons];
        const updatelesson = lessons.filter(
          (les) => les.lessonId !== lesson.lessonId
        );
        await deleteLessonInDb(lesson.lessonId);
        this.setState({ lessons: updatelesson, loading: false });
      } catch (error) {
        this.setState({ loading: false });
        alert(error.message);
      }
    }
  };

  updateLesson = (lesson) => {
    this.props.history.push({
      pathname: "/lessons/create-new-lesson",
      state: { lesson: lesson, isEditing: true },
    });
  };

  raiseSort = (path) => {
    let sortColoumn = { ...this.state.sortColoumn };
    sortColoumn.path = path;
    if (sortColoumn.order === "asc") {
      sortColoumn.order = "desc";
    } else {
      sortColoumn.order = "asc";
    }
    this.setState({ sortColoumn });
  };

  render() {
    const { lessons, sortColoumn } = this.state;
    const sortedLessons = _.orderBy(
      lessons,
      [sortColoumn.path],
      [sortColoumn.order]
    );

    return (
      <Sidebar>
        {this.state.loading ? (
          <Spinner />
        ) : (
          <div>
            <h2>Lessons</h2>
            <Link
              className="btn btn-block primary-button-outline  mb-2"
              to={{
                pathname: "/lessons/create-new-lesson",
                state: { isEditing: false },
              }}
            >
              Create New Lesson
              <span className="ml-2 fs-20">
                <RiAddCircleLine />
              </span>
            </Link>
            <LessonsTable
              data={sortedLessons}
              lessonClicked={this.lessonClickHandler}
              deleteLesson={this.deleteLesson}
              raiseSort={this.raiseSort}
              updateLesson={this.updateLesson}
            />
          </div>
        )}
      </Sidebar>
    );
  }
}

export default withRouter(Lessons);
