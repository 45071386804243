import React, { Component } from "react";
import { RiAddCircleLine } from "react-icons/ri";
import { connect } from "react-redux";
import Input from "../../Common/Input";
import { ImCross } from "react-icons/im";
import Modal from "../../Common/Modal";
import { addMultiplechoiceAudioExercise } from "../../../Firebase/Firebase";
import { withRouter } from "react-router";
import { FiEdit } from "react-icons/fi";
import VoiceRecorder from "../../Common/VoiceRecorder";
import { FaMicrophone } from "react-icons/fa";

class MultipleChoiceAudio extends Component {
  state = {
    loading: true,
    isEditing: false,
    multipleChoicesAnswer: [],
    exercise_instruction_german: "",
    exercise_instruction_english: "",
    question_audio: null,
    question_text: "",
    question_audio_url: null,
    correct_choice: false,
    isEditingChoice: false,
    id: "",
    choice_text: "",
    errors: {},
  };

  componentDidMount() {
    const isEditing = this.props.isEditing;
    const exercise = this.props.exercise;
    if (isEditing) {
      this.setState({
        exercise_instruction_english: exercise.exercise_instruction_english,
        exercise_instruction_german: exercise.exercise_instruction_german,
        question_audio_url: exercise.question_audio,
        question_text: exercise.question_text,
        multipleChoicesAnswer: exercise.multipleChoicesAnswer,
        isEditing,
      });
    }

    this.setState({ loading: false });
  }

  fileInputHandler = (e) => {
    this.setState({ [e.target.name]: e.target.files[0] });
  };

  exerciseTypeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  exerciseInputHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  choiceInputHandler = (e) => {
    const errors = { ...this.state.errors };
    if (e.target.value.length > 100) {
      errors[e.target.name] = "Charecter should be between 1 - 100";
      this.setState({ errors, [e.target.name]: e.target.value });
      return;
    } else {
      delete errors[e.target.name];
      this.setState({
        [e.target.name]: e.target.value,
        errors,
      });
    }
  };

  deleteChoice = (id) => {
    let multipleChoicesAnswer = [...this.state.multipleChoicesAnswer];
    multipleChoicesAnswer = multipleChoicesAnswer.filter(
      (item) => item.id !== id
    );
    this.setState({ multipleChoicesAnswer });
  };

  checkCorrectOption = () => {
    const itemFound = this.state.multipleChoicesAnswer.find(
      (item) => item.correct_choice === true
    );
    return itemFound ? true : false;
  };

  handleFile = (file) => {
    this.setState({ question_audio: file });
    alert("Uploaded");
  };

  addChoice = () => {
    const { correct_choice, choice_text, isEditingChoice, id } = this.state;

    if (
      choice_text === "" ||
      choice_text.length > 100 ||
      choice_text.trim().length === 0
    ) {
      return alert(
        "Choice should not be empty and must be less than the 100 charecters"
      );
    }

    let multipleChoicesAnswer = [...this.state.multipleChoicesAnswer];
    const findItem = multipleChoicesAnswer.find(
      (item) => item.id !== id && item.correct_choice === true
    );

    if (findItem && correct_choice) {
      return alert("Correct choice only be one.");
    }

    const choiceId = isEditingChoice ? id : Math.random() * 2000;

    if (isEditingChoice) {
      const index = multipleChoicesAnswer.findIndex(
        (item) => item.id === choiceId
      );

      multipleChoicesAnswer[index].choice_text = choice_text;
      multipleChoicesAnswer[index].correct_choice = correct_choice;
    } else {
      multipleChoicesAnswer.push({
        id: choiceId,
        correct_choice,
        choice_text,
      });
    }

    this.setState({
      correct_choice: false,
      choice_text: "",
      isEditingChoice: false,
      multipleChoicesAnswer,
      id: "",
    });
    alert(isEditingChoice ? "Edited successfully" : "Added successfully");
  };

  editChoice = (item) => {
    this.setState({
      choice_text: item.choice_text,
      correct_choice: item.correct_choice,
      isEditingChoice: true,
      id: item.id,
    });
  };

  saveExercise = async () => {
    const {
      multipleChoicesAnswer,
      question_audio,
      question_audio_url,
      exercise_instruction_german,
      isEditing,
      exercise_instruction_english,
      question_text,
    } = this.state;
    const instructor = this.props.user;
    if (
      exercise_instruction_german === "" ||
      exercise_instruction_german.trim().length === 0
    ) {
      alert("Instruction in German should be required");
      return;
    }
    if (
      exercise_instruction_english === "" ||
      exercise_instruction_english.trim().length === 0
    ) {
      alert("Instruction in English ashould be required");
      return;
    }
    if (question_text === "") {
      alert("Question in text are required");
      return;
    }
    if (!isEditing) {
      if (question_audio === null) {
        alert("Question in Audio are required");
        return;
      }
      if (multipleChoicesAnswer.length < 2) {
        alert("At least two choices are required");
        return;
      }
      if (!this.checkCorrectOption()) {
        alert("At least one choice should be correct");
        return;
      }
    }

    const data = {
      lessonId: this.props.lessonId,
      lesson_level: this.props.lesson_level,
      exercise_type: this.props.exercise_type,
      instructorId: instructor.uid,
      exercise_instruction_english,
      exercise_instruction_german,
      multipleChoicesAnswer,
      exerciseId: this.props.exercise ? this.props.exercise.exerciseId : null,
      question_audio,
      question_audio_url,
      question_text,
      order: 3,
    };

    this.props.setLoading(true);
    try {
      if (isEditing) {
        await addMultiplechoiceAudioExercise(data, isEditing);
        this.props.history.push(`/lessons/lesson/${this.props.lessonId}`);
      } else {
        await addMultiplechoiceAudioExercise(data, isEditing);
        this.props.history.push(`/lessons/lesson/${this.props.lessonId}`);
      }
    } catch (error) {
      this.props.setLoading(false);
      alert(error.message);
    }
  };

  render() {
    return !this.state.loading ? (
      <div>
        <Input
          name="exercise_instruction_german"
          label="Instruction (German)"
          onChange={this.exerciseInputHandler}
          value={this.state.exercise_instruction_german}
          error={this.state.errors.exercise_instruction_german}
          type="text"
          placeholder="Exercise Instruction (German)"
        />

        <Input
          name="exercise_instruction_english"
          label="Instruction (English)"
          value={this.state.exercise_instruction_english}
          onChange={this.exerciseInputHandler}
          error={this.state.errors.exercise_instruction_english}
          type="text"
          placeholder="Exercise Instruction (English)"
        />

        <div>
          <div className="row mb-2">
            <div className="col-sm-7">
              <div className="row">
                <div className="col-6">
                  <label className="font-weight-bold mt-1">
                    Question Audio
                  </label>
                </div>
                <div className="col-4">
                  <div>
                    <input
                      name="question_audio"
                      type="file"
                      accept="audio/*"
                      onChange={this.fileInputHandler}
                      className="form-check-input"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              <audio
                controls
                src={
                  this.state.question_audio
                    ? URL.createObjectURL(this.state.question_audio)
                    : this.state.question_audio_url
                    ? this.state.question_audio_url
                    : null
                }
              >
                Your browser does not support the audio element.
              </audio>
            </div>

            <div className="col-sm-1 d-flex align-items-center">
              <button
                className="btn "
                data-toggle="modal"
                data-target="#recorderModal"
              >
                <span className="ml-1 fs-20">
                  <FaMicrophone />
                </span>
              </button>
            </div>
          </div>
        </div>

        <Input
          name="question_text"
          label="Question"
          onChange={this.exerciseInputHandler}
          value={this.state.question_text}
          error={this.state.errors.question_text}
          type="text"
          placeholder="Question Text"
        />
        <div className="row">
          <div className="col-sm-3">
            <label className="font-weight-bold mt-1">Multiple Choices</label>
          </div>
          <div className="col-sm-9 card">
            <button
              className="btn primary-button-outline  my-2 "
              data-toggle="modal"
              data-target="#paymentmodal"
            >
              Add Choice
              <span className="ml-2 fs-20">
                <RiAddCircleLine />
              </span>
            </button>
            <ul className="list-group mb-3">
              {this.state.multipleChoicesAnswer.map((item) => (
                <div key={item.id} className="row my-2">
                  <div className="col-10">
                    <span> {item.choice_text}</span>
                  </div>

                  <div className="col-1">
                    <span
                      className="pointer"
                      onClick={() => this.editChoice(item)}
                      data-toggle="modal"
                      data-target="#paymentmodal"
                    >
                      <FiEdit />
                    </span>
                  </div>
                  <div className="col-1">
                    <span
                      style={{ color: "red" }}
                      className="pointer"
                      onClick={() => this.deleteChoice(item.id)}
                    >
                      <ImCross />
                    </span>
                  </div>
                </div>
              ))}
            </ul>
          </div>
        </div>

        <button className="btn primary-button mt-3" onClick={this.saveExercise}>
          {this.state.isEditing ? "Save  Changes" : "Add Exercise"}
        </button>

        <div
          className="modal fade"
          id="recorderModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="recordLabel"
          aria-hidden="true"
        >
          <div className={`modal-dialog  modal-lg`} role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="recordLabel">
                  Record Audio
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <VoiceRecorder handleFile={this.handleFile} />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn primary-button"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>

        <Modal
          label={this.state.isEditingChoice ? "Edit Choice" : "Add Choice"}
          buttonLabel={
            this.state.isEditingChoice ? "Edit Choice" : "Create choice"
          }
          modal_size_class="modal-lg"
          buttonClicked={this.addChoice}
        >
          <Input
            name="choice_text"
            label="Choice"
            value={this.state.choice_text}
            error={this.state.errors.choice_text}
            onChange={this.choiceInputHandler}
            type="text"
            placeholder="Choice label"
          />
          <div className="row">
            <div className="col-sm-3">
              <label className="font-weight-bold mt-1">Correct Choice</label>
            </div>
            <div className="col-sm-9">
              <div className="form-check">
                <input
                  type="checkbox"
                  name="correct_choice"
                  checked={this.state.correct_choice}
                  style={{ padding: "10px", transform: "scale(1.3)" }}
                  onChange={this.exerciseTypeHandler}
                  className="form-check-input"
                  id="exampleCheck1"
                />
                <label className="form-check-label" htmlFor="exampleCheck1">
                  is it correct option?
                </label>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    ) : (
      ""
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(withRouter(MultipleChoiceAudio));
