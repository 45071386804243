import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import Input from "./../Common/Input2";
import Form from "./../Common/Form";
import { createLesson, updateLesson } from "../../Firebase/Firebase";
import Joi from "joi";
import Spinner from "../Common/Spinner";
import { connect } from "react-redux";
import TextArea from "./../Common/TextArea";
import Select from "../Common/Select1";
import { lesson_levels } from "../Util/data";

class NewLesson extends Form {
  state = {
    data: {
      lesson_code: "",
      lesson_level: "",
      name_german: "",
      name_english: "",
      explaination_german: "",
      explaination_english: "",
    },
    loading: false,
    isEditing: false,
    errors: {},
  };

  async componentDidMount() {
    const isEditing = this.props.location.state.isEditing;
    if (isEditing) {
      const lesson = this.props.location.state.lesson;
      const updatingData = { ...this.state.data };
      updatingData.lesson_level = lesson.lesson_level;
      updatingData.name_german = lesson.name_german;
      updatingData.lesson_code = lesson.lesson_code;
      updatingData.name_english = lesson.name_english;
      updatingData.explaination_english = lesson.explaination_english;
      updatingData.explaination_german = lesson.explaination_german;

      this.setState({ data: updatingData, isEditing });
    }
  }

  objectSchema = {
    lesson_code: Joi.string().required().label("Code is Required"),
    name_german: Joi.string().max(50).required().label("Title in German"),
    explaination_german: Joi.string()
      .required()
      .label("Explaination for German"),
    name_english: Joi.string().max(50).required().label("Title in English"),
    explaination_english: Joi.string()
      .required()
      .label("Explaination for english"),
    lesson_level: Joi.string().required().label("Lesson Level is required"),
  };

  doSubmit = async () => {
    try {
      this.setState({ loading: true });

      if (this.state.isEditing) {
        await updateLesson(
          this.state.data.lesson_code,
          this.state.data.name_german,
          this.state.data.explaination_german,
          this.state.data.name_english,
          this.state.data.explaination_english,
          this.state.data.lesson_level,
          this.props.user.uid,
          this.props.location.state.lesson.lessonId
        );
      } else {
        await createLesson(
          this.state.data.lesson_code,
          this.state.data.name_german,
          this.state.data.explaination_german,
          this.state.data.name_english,
          this.state.data.explaination_english,
          this.state.data.lesson_level,
          this.props.user.uid
        );
      }
      this.props.history.push("/lessons");
      this.setState({
        loading: false,
      });
    } catch (error) {
      this.setState({
        loading: false,
      });
      alert(error.message);
    }
  };
  render() {
    return (
      <Sidebar>
        {this.state.loading ? (
          <Spinner />
        ) : (
          <div>
            <h1 className="mb-4">
              {this.state.isEditing ? "Edit Lesson" : "Create New Lesson"}
            </h1>
            <Select
              data={lesson_levels}
              name="lesson_level"
              label="Lesson Level"
              value={this.state.data.lesson_level}
              onChange={this.inputHandler}
              error={this.state.errors.lesson_level}
            />
            <Input
              name="lesson_code"
              label="Lesson Code"
              onChange={this.inputHandler}
              error={this.state.errors.lesson_code}
              value={this.state.data.lesson_code}
              type="text"
              placeholder="Enter Code of Lesson"
            />
            <Input
              name="name_german"
              label="Name (German)"
              onChange={this.inputHandler}
              error={this.state.errors.name_german}
              value={this.state.data.name_german}
              type="text"
              placeholder="Enter Name of Lesson"
            />
            <TextArea
              label="Explaination (German)"
              name="explaination_german"
              onChange={this.inputHandler}
              value={this.state.data.explaination_german}
              error={this.state.errors.explaination_german}
              placeholder="Explaination for German"
            />
            <Input
              name="name_english"
              label="Name (English)"
              onChange={this.inputHandler}
              error={this.state.errors.name_english}
              value={this.state.data.name_english}
              type="text"
              placeholder="Enter Name of Lesson"
            />
            <TextArea
              label="Explaination (English)"
              name="explaination_english"
              onChange={this.inputHandler}
              value={this.state.data.explaination_english}
              error={this.state.errors.explaination_english}
              placeholder="Explaination for English"
            />
            <button
              onClick={this.submitForm}
              className="btn primary-button px-5 mt-3"
            >
              {this.state.isEditing ? "Save changes" : "Submit"}
            </button>
          </div>
        )}
      </Sidebar>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(NewLesson);
