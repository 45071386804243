import React, { Component } from "react";
import Sidebar from "../Sidebar/Sidebar";
import Joi from "joi";
import Input from "../../Common/Input";
import Spinner from "../../Common/Spinner";
import profileImage from "../../../assets/placeholderimage.png";
import { editInstructor } from "../../../Firebase/Firebase";
import { FaEdit } from "react-icons/fa";
import { withRouter } from "react-router";
import Form from "../../Common/Form";
import Checkbox from "../../Common/Checkbox";
var aesjs = require("aes-js");

class Profile extends Form {
  state = {
    loading: false,
    isEditing: false,
    id: "",
    image: null,
    src: null,
    isAdmin: false,
    data: {
      name: "",
      email: "",
      password: "",
    },
    address: "",
    mobile_phone: "",
    errors: {},
  };
  async componentDidMount() {
    const isEditing = this.props.location.state.isEditing;
    const instructor = this.props.location.state.instructor;
    if (isEditing) {
      const account = { ...this.state.data };
      account.name = instructor.name;
      account.email = instructor.email;
      account.password = instructor.password;
      this.setState({
        data: account,
        id: instructor.uid,
        src: instructor.profile_image,
        address: instructor.address,
        mobile_phone: instructor.mobile_phone,
        isAdmin: instructor.isAdmin,
        isEditing,
      });
    }
  }

  getEncryptedPassword = (pass) => {
    // An example 128-bit key (16 bytes * 8 bits/byte = 128 bits)
    var key = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16];
    // Convert text to bytes
    var text = pass;
    var textBytes = aesjs.utils.utf8.toBytes(text);
    // The counter is optional, and if omitted will begin at 1
    var aesCtr = new aesjs.ModeOfOperation.ctr(key, new aesjs.Counter(5));
    var encryptedBytes = aesCtr.encrypt(textBytes);

    // To print or store the binary data, you may convert it to hex
    var encryptedHex = aesjs.utils.hex.fromBytes(encryptedBytes);

    return encryptedHex;
  };

  getDecryptedPassword = (pass) => {
    var key = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16];
    var encryptedBytes = aesjs.utils.hex.toBytes(pass);
    var aesCtr = new aesjs.ModeOfOperation.ctr(key, new aesjs.Counter(5));
    var decryptedBytes = aesCtr.decrypt(encryptedBytes);

    // Convert our bytes back into text
    var decryptedText = aesjs.utils.utf8.fromBytes(decryptedBytes);
    return decryptedText;
  };

  fileInputHandler = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        this.setState({ src: reader.result, image: e.target.files[0] });
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  objectSchema = {
    name: Joi.string().required(),
    email: Joi.string().required(),
    password: Joi.string().required(),
  };

  inputChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  checkboxHandler = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  doSubmit = async () => {
    let { password, email, name } = this.state.data;
    let {
      address,
      mobile_phone,
      id,
      src,
      image,
      isEditing,
      isAdmin,
    } = this.state;

    try {
      this.setState({ loading: true });
      password = this.getEncryptedPassword(password);
      await editInstructor(
        isEditing,
        id,
        src,
        image,
        email,
        password,
        name,
        address,
        mobile_phone,
        isAdmin
      );

      alert(isEditing ? "Successfully Updated." : "Successfully Created.");
      this.props.history.push("/admin/instructors");
      this.setState({
        loading: false,
      });
    } catch (error) {
      this.setState({
        loading: false,
      });
      alert(error.message);
    }
  };

  render() {
    let {
      data: instructor,
      errors,
      src,
      isEditing,
      mobile_phone,
      address,
      isAdmin,
    } = this.state;

    return (
      <Sidebar>
        {this.state.loading ? (
          <Spinner />
        ) : (
          <div className="edit__profile_container">
            <div className="edit__profile_title">
              <h3>{isEditing ? "Edit Instructor" : "Create Instructor"}</h3>
            </div>
            <hr color="	#DCDCDC" />

            <h4 className="mt-4">Basic Info</h4>
            <div className="card p-3">
              <div className="vendor__profile_img mb-3">
                <div className="image-upload">
                  <label htmlFor="file-input">
                    <FaEdit />
                  </label>
                  <input
                    id="file-input"
                    type="file"
                    name="image"
                    accept="image/*"
                    onChange={this.fileInputHandler}
                  />
                </div>
                <img src={src ? src : profileImage} alt=""></img>
              </div>
              <Checkbox
                name="isAdmin"
                label="Admin"
                onChange={this.checkboxHandler}
                checked={isAdmin}
              />
              <Input
                name="name"
                label="Name"
                onChange={this.inputHandler}
                error={errors.name}
                value={instructor.name}
                type="text"
                placeholder="Enter instructor Name"
              />
              <Input
                name="email"
                label="Email"
                onChange={this.inputHandler}
                error={errors.email}
                value={instructor.email}
                type="email"
                placeholder="Enter instructor Email"
              />
              <Input
                name="password"
                label="Password"
                onChange={this.inputHandler}
                error={errors.password}
                value={instructor.password}
                type="password"
                placeholder="Enter password"
              />
              <Input
                name="mobile_phone"
                label="Mobile Phone"
                onChange={this.inputChangeHandler}
                value={mobile_phone}
                type="number"
                placeholder="Enter instructor Number"
              />
              <Input
                name="address"
                label="Address"
                onChange={this.inputChangeHandler}
                value={address}
                type="text"
                placeholder="Enter instructor Address"
              />
            </div>

            <div>
              <button
                className="btn primary-button mt-2 px-5 mb-3"
                onClick={this.submitForm}
              >
                {isEditing ? "Save Changes" : "Create"}
              </button>
            </div>
          </div>
        )}
      </Sidebar>
    );
  }
}

export default withRouter(Profile);
