import React, { Component } from "react";
import NavItem from "./NavItem";
import { FaGraduationCap, FaUserGraduate } from "react-icons/fa";
import { RiAdvertisementFill } from "react-icons/ri";
import { FiLogOut } from "react-icons/fi";
import { BsBook } from "react-icons/bs";

class Sidebar extends Component {
  state = { active: false };

  componentDidMount() {
    const expanded = localStorage.getItem("expanded");
    if (expanded) {
      expanded === "true"
        ? this.setState({ active: true })
        : this.setState({ active: false });
    }
  }
  logout = () => {
    const logout = window.confirm("Are you sure you want to logout?");
    if (logout) {
      sessionStorage.removeItem("instructor");
      window.location.replace("/admin");
    }
  };

  handleActive = () => {
    this.setState({
      active: !this.state.active,
    });
    localStorage.setItem("expanded", !this.state.active);
  };

  render() {
    return (
      <div className={this.state.active ? "wrapper active" : "wrapper"}>
        <div className="main_body">
          <div className="sidebar_menu">
            <div className="inner__sidebar_menu">
              <ul>
                <div className="main_logo">
                  <h4>Speakeasy</h4>
                </div>
                <NavItem
                  path="/admin/lessons"
                  Icon={FaGraduationCap}
                  label="Lessons"
                />
                <NavItem
                  path="/admin/instructors"
                  Icon={FaUserGraduate}
                  label="Instructors"
                />
                <NavItem
                  path="/admin/advertisements"
                  Icon={RiAdvertisementFill}
                  label="Advertisement"
                />
                <NavItem path="/admin/courses" Icon={BsBook} label="Courses" />
                <li className="pointer">
                  <div onClick={this.logout}>
                    <span className="icon">
                      <FiLogOut />
                    </span>
                    <span className="list">Logout</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div className="sidebar-container">{this.props.children}</div>
        </div>
      </div>
    );
  }
}

export default Sidebar;
