import React, { Component } from "react";
import { HiOutlineMail } from "react-icons/hi";
import { RiLockPasswordLine } from "react-icons/ri";
import InputWithIcon from "./../../Common/InputWithIcon";
import LoginButton from "./LoginButton";
import { connect } from "react-redux";
import { getUser } from "./../../../Actions/Auth";
import { withRouter } from "react-router-dom";
class Login extends Component {
  state = { loading: false, email: "", password: "" };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  setLoading = () => {
    this.setState({
      loading: !this.state.loading,
    });
  };

  login = async () => {
    this.setLoading();
    try {
      await this.props.getUser(this.state.email, this.state.password);
      if (Object.keys(this.props.user).length === 0) {
        this.setLoading();
        return;
      }

      if (this.props.user && !this.props.user.isAdmin) {
        this.setLoading();
        alert("Invalid Credentials");
        return;
      }

      window.location.reload("/");
      this.setLoading();
    } catch (error) {
      this.setLoading();
      alert(error.message);
    }
  };
  render() {
    return (
      <div className="login__background">
        <div className="login__container">
          <div>
            <form className="login__form">
              <h2>Admin Login</h2>

              <InputWithIcon
                Icon={HiOutlineMail}
                name="email"
                label="Email ID"
                onChange={(e) => this.handleChange(e)}
                type="email"
                placeholder="youremail@gmail.com"
              />
              <InputWithIcon
                Icon={RiLockPasswordLine}
                name="password"
                label="Password"
                onChange={(e) => this.handleChange(e)}
                type="password"
                placeholder="********"
              />

              <LoginButton loading={this.state.loading} login={this.login} />
            </form>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, { getUser })(withRouter(Login));
