import { firestore, auth, storageRef } from "./FirebaseConfig";
var aesjs = require("aes-js");

const uploadAudio = async (file) => {
  const uid = Date.now();
  const storageRefrence = storageRef.ref("/AudioFiles").child("/" + uid);
  const uploadedFile = await storageRefrence.put(file);
  return uploadedFile.ref.getDownloadURL();
};

const uploadImage = async (file, source) => {
  const uid = Date.now();
  const storageRefrence = storageRef.ref(source).child("/" + uid);
  const uploadedFile = await storageRefrence.put(file);
  return uploadedFile.ref.getDownloadURL();
};

//Lesson Queries
export const createLesson = async (
  lesson_code,
  name_german,
  explaination_german,
  name_english,
  explaination_english,
  lesson_level,
  instructorId
) => {
  const documentRef = await firestore.collection("Lessons").doc();
  const data = {
    lesson_code,
    name_english,
    name_german,
    explaination_german,
    explaination_english,
    lesson_level,
    instructorId,
    status: false,
    exercise_types: [],
    lessonId: documentRef.id,
  };
  await documentRef.set(data);
};

async function updateLessonLevels(lessons, lesson_level) {
  const j = lessons.length;
  for (let i = 0; i < j; i++) {
    await firestore
      .collection("Lesson_Levels")
      .doc(lessons[i].data().exerciseId)
      .update({ lesson_level });
  }
}

export const updateLesson = async (
  lesson_code,
  name_german,
  explaination_german,
  name_english,
  explaination_english,
  lesson_level,
  instructorId,
  lessonId
) => {
  const documentRef = await firestore.collection("Lessons").doc(lessonId);
  const lessons = await firestore
    .collection("Lesson_Levels")
    .where("lessonId", "==", lessonId)
    .get();
  await updateLessonLevels(lessons.docs, lesson_level);
  const data = {
    name_english,
    lesson_code,
    name_german,
    explaination_german,
    explaination_english,
    lesson_level,
    instructorId,
    lessonId,
  };
  await documentRef.update(data);
};

export const getLessons = async () => {
  const lessons = await firestore.collection("Lessons").get();
  const AllLessons = [];
  lessons.docs.forEach((lesson) => {
    AllLessons.push({ ...lesson.data(), id: lesson.data().lessonId });
  });
  return AllLessons;
};
export const getLesson = async (id) => {
  const response = await firestore.collection("Lessons").doc(id).get();
  return response.data();
};

export const changelessonStatus = async (status, id) => {
  const lessons = await firestore
    .collection("Lessons")
    .doc(id)
    .update({ status });
};
export const deleteLessonInDb = async (lessonId) => {
  await firestore.collection("Lessons").doc(lessonId).delete();
};

export const submitLessonSetting = async (image, imageUrl, text) => {
  let image_url = imageUrl;
  if (image) {
    image_url = await uploadImage(image, "Lessons");
  }
  const ref = await firestore
    .collection("LessonSetting")
    .doc("lesson_settings");
  const data = {
    image_url,
    text,
  };
  await ref.set(data);
};

export const getLessonSetting = async () => {
  const ref = await firestore
    .collection("LessonSetting")
    .doc("lesson_settings")
    .get();
  if (ref.exists) {
    return ref.data();
  }

  return null;
};

// Exercise
export const addExercise = async (data, isEditing) => {
  if (isEditing) {
    const documentRef = await firestore
      .collection("Lesson_Levels")
      .doc(data.exerciseId);
    await documentRef.update(data);
  } else {
    const { lessonId, exercise_type } = data;

    const lesson = await firestore.collection("Lessons").doc(lessonId).get();
    const index = lesson
      .data()
      .exercise_types.findIndex((item) => item === exercise_type);

    if (index < 0) {
      await firestore
        .collection("Lessons")
        .doc(lessonId)
        .update({
          exercise_types: [exercise_type, ...lesson.data().exercise_types],
        });
    }

    const documentRef = await firestore.collection("Lesson_Levels").doc();
    const exercise_data = {
      ...data,
      exerciseId: documentRef.id,
    };
    await documentRef.set(exercise_data);
  }
};

export const addMultiplechoiceAudioExercise = async (data, isEditing) => {
  let {
    lessonId,
    lesson_level,
    exercise_type,
    instructorId,
    exercise_instruction_english,
    exercise_instruction_german,
    multipleChoicesAnswer,
    exerciseId,
    question_audio,
    question_audio_url,
    question_text,
    order,
  } = data;
  if (isEditing) {
    const documentRef = await firestore
      .collection("Lesson_Levels")
      .doc(exerciseId);

    if (question_audio) {
      question_audio = await uploadAudio(data.question_audio);
    } else {
      question_audio = question_audio_url;
    }
    const exercise_data = {
      lessonId,
      lesson_level,
      exercise_type,
      instructorId,
      exercise_instruction_english,
      exercise_instruction_german,
      multipleChoicesAnswer,
      exerciseId,
      question_audio,
      question_text,
    };

    await documentRef.update(exercise_data);
  } else {
    const lesson = await firestore.collection("Lessons").doc(lessonId).get();
    const index = lesson
      .data()
      .exercise_types.findIndex((item) => item === exercise_type);

    if (index < 0) {
      await firestore
        .collection("Lessons")
        .doc(lessonId)
        .update({
          exercise_types: [exercise_type, ...lesson.data().exercise_types],
        });
    }
    const documentRef = await firestore.collection("Lesson_Levels").doc();

    question_audio = await uploadAudio(question_audio);

    const exercise_data = {
      lessonId,
      lesson_level,
      exercise_type,
      instructorId,
      exercise_instruction_english,
      exercise_instruction_german,
      multipleChoicesAnswer,
      question_audio,
      question_text,
      order,
      exerciseId: documentRef.id,
    };

    await documentRef.set(exercise_data);
  }
};

export const addWordOrderExercise = async (data, isEditing) => {
  let {
    lessonId,
    lesson_level,
    exercise_type,
    instructorId,
    exercise_instruction_english,
    exercise_instruction_german,
    exerciseId,
    correct_order_audio_file,
    correct_order_audio_url,
    correct_order,
    incorrect_order_audio_file,
    incorrect_order_audio_url,
    incorrect_order,
    order,
  } = data;

  if (isEditing) {
    const documentRef = await firestore
      .collection("Lesson_Levels")
      .doc(exerciseId);

    if (correct_order_audio_file) {
      correct_order_audio_file = await uploadAudio(correct_order_audio_file);
    } else {
      correct_order_audio_file = correct_order_audio_url;
    }
    if (incorrect_order_audio_file) {
      incorrect_order_audio_file = await uploadAudio(
        incorrect_order_audio_file
      );
    } else {
      incorrect_order_audio_file = incorrect_order_audio_url;
    }

    const exercise_data = {
      lessonId,
      lesson_level,
      exercise_type,
      instructorId,
      exercise_instruction_english,
      exercise_instruction_german,
      exerciseId,
      correct_order_audio_file,
      correct_order,
      incorrect_order_audio_file,
      incorrect_order,
      order,
    };

    await documentRef.update(exercise_data);
  } else {
    const documentRef = await firestore.collection("Lesson_Levels").doc();

    correct_order_audio_file = await uploadAudio(correct_order_audio_file);

    incorrect_order_audio_file = await uploadAudio(incorrect_order_audio_file);

    const exercise_data = {
      lessonId,
      lesson_level,
      exercise_type,
      instructorId,
      exercise_instruction_english,
      exercise_instruction_german,
      exerciseId: documentRef.id,
      correct_order_audio_file,
      incorrect_order_audio_file,
      incorrect_order,
      correct_order,
      order,
    };

    await documentRef.set(exercise_data);
  }
};

export const addTypeAnswerExercise = async (data, isEditing) => {
  let {
    lessonId,
    lesson_level,
    exercise_type,
    instructorId,
    exercise_instruction_english,
    exercise_instruction_german,
    exerciseId,
    question_audio_file,
    question_audio_url,
    question_text,
    answer_text,
    order,
  } = data;
  if (isEditing) {
    const documentRef = await firestore
      .collection("Lesson_Levels")
      .doc(exerciseId);

    if (question_audio_file) {
      question_audio_file = await uploadAudio(question_audio_file);
    } else {
      question_audio_file = question_audio_url;
    }

    const exercise_data = {
      lessonId,
      lesson_level,
      exercise_type,
      instructorId,
      exercise_instruction_english,
      exercise_instruction_german,
      exerciseId,
      question_audio_file,
      question_text,
      answer_text,
      order,
    };

    await documentRef.update(exercise_data);
  } else {
    const lesson = await firestore.collection("Lessons").doc(lessonId).get();

    console.log(lesson.data());
    const index = lesson
      .data()
      .exercise_types.findIndex((item) => item === exercise_type);

    if (index < 0) {
      await firestore
        .collection("Lessons")
        .doc(lessonId)
        .update({
          exercise_types: [exercise_type, ...lesson.data().exercise_types],
        });
    }

    const documentRef = await firestore.collection("Lesson_Levels").doc();

    question_audio_file = await uploadAudio(question_audio_file);
    const exercise_data = {
      lessonId,
      lesson_level,
      exercise_type,
      instructorId,
      exercise_instruction_english,
      exercise_instruction_german,
      question_audio_file,
      exerciseId: documentRef.id,
      question_text,
      answer_text,
      order,
    };

    await documentRef.set(exercise_data);
  }
};

export const addSpeakingExercise = async (data, isEditing) => {
  let {
    lessonId,
    lesson_level,
    exercise_type,
    instructorId,
    exercise_instruction_english,
    exercise_instruction_german,
    exerciseId,
    question_audio_file,
    question_audio_url,
    answer_audio_file,
    answer_audio_url,
    question_text,
    answer_text,
    order,
  } = data;
  if (isEditing) {
    const documentRef = await firestore
      .collection("Lesson_Levels")
      .doc(exerciseId);

    if (question_audio_file) {
      question_audio_file = await uploadAudio(question_audio_file);
    } else {
      question_audio_file = question_audio_url;
    }

    if (answer_audio_file) {
      answer_audio_file = await uploadAudio(answer_audio_file);
    } else {
      answer_audio_file = answer_audio_url;
    }

    const exercise_data = {
      lessonId,
      lesson_level,
      exercise_type,
      instructorId,
      exercise_instruction_english,
      exercise_instruction_german,
      exerciseId,
      question_audio_file,
      answer_audio_file,
      question_text,
      answer_text,
      order,
    };

    await documentRef.update(exercise_data);
  } else {
    const lesson = await firestore.collection("Lessons").doc(lessonId).get();

    console.log(lesson.data());
    const index = lesson
      .data()
      .exercise_types.findIndex((item) => item === exercise_type);

    if (index < 0) {
      await firestore
        .collection("Lessons")
        .doc(lessonId)
        .update({
          exercise_types: [exercise_type, ...lesson.data().exercise_types],
        });
    }

    const documentRef = await firestore.collection("Lesson_Levels").doc();

    question_audio_file = await uploadAudio(question_audio_file);
    answer_audio_file = await uploadAudio(answer_audio_file);
    const exercise_data = {
      lessonId,
      lesson_level,
      exercise_type,
      instructorId,
      exercise_instruction_english,
      exercise_instruction_german,
      question_audio_file,
      answer_audio_file,
      exerciseId: documentRef.id,
      question_text,
      answer_text,
      order,
    };

    await documentRef.set(exercise_data);
  }
};

export const getExercises = async (lessonId) => {
  const result = await firestore
    .collection("Lesson_Levels")
    .where("lessonId", "==", lessonId)
    .get();
  const exer = [];
  result.docs.forEach((exercise) => {
    exer.push(exercise.data());
  });
  return exer;
};

export const deleteExercise = async (exerciseId) => {
  await firestore.collection("Lesson_Levels").doc(exerciseId).delete();
};

// Auth Queries
export const createUserInDb = async (
  name,
  mobile_phone,
  address,
  email,
  password,
  status,
  created_on,
  last_login
) => {
  const res = await auth.createUserWithEmailAndPassword(email, password);
  await auth.currentUser.sendEmailVerification();
  const documentRef = firestore.collection("Instructors").doc(res.user.uid);
  const data = {
    name,
    mobile_phone,
    address,
    email,
    status,
    emailVerified: false,
    created_on,
    profile_image: null,
    last_login,
    uid: res.user.uid,
  };
  await documentRef.set(data);
  const response = await firestore
    .collection("Instructors")
    .doc(res.user.uid)
    .get();
  return response.data();
};

export const editInstructor = async (
  isEditing,
  id,
  src,
  image,
  email,
  password,
  name,
  address,
  mobile_phone,
  isAdmin
) => {
  let profile_image = src;
  if (image !== null) {
    profile_image = await uploadImage(image, "/profile");
  }

  if (!isEditing) {
    const documentRef = firestore.collection("Instructors").doc();
    const userData = {
      email,
      name,
      mobile_phone,
      address,
      password,
      profile_image,
      status: true,
      isAdmin,
      uid: documentRef.id,
    };
    await documentRef.set(userData);
  } else {
    const documentRef = firestore.collection("Instructors").doc(id);
    const userData = {
      email,
      name,
      mobile_phone,
      address,
      password,
      profile_image,
      isAdmin,
    };
    await documentRef.update(userData);
  }
};

export const deleteInstructorFromDB = async (instructor) => {
  await firestore.collection("Instructors").doc(instructor.uid).delete();
};

export const getUser = async (id) => {
  const res = await firestore.collection("Instructors").doc(id).get();
  if (res.exists) {
    return res.data();
  }
  return null;
};

export const forgotPassword = async (email) => {
  await auth.sendPasswordResetEmail(email);
};

export const getDecryptedPassword = (pass) => {
  var key = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16];
  var encryptedBytes = aesjs.utils.hex.toBytes(pass);
  var aesCtr = new aesjs.ModeOfOperation.ctr(key, new aesjs.Counter(5));
  var decryptedBytes = aesCtr.decrypt(encryptedBytes);

  // Convert our bytes back into text
  var decryptedText = aesjs.utils.utf8.fromBytes(decryptedBytes);
  return decryptedText;
};

export const getCurrentUser = async (email, password) => {
  const result = await firestore.collection("Instructors").get();
  let inst = null;
  result.docs.forEach((insructor) => {
    if (
      insructor.data().email === email &&
      getDecryptedPassword(insructor.data().password) === password
    ) {
      inst = insructor.data();
    }
  });

  if (inst) {
    sessionStorage.setItem("instructor", JSON.stringify(inst));
    return inst;
  }

  alert("Invalid Credentials");
  return null;
};

export const getAdmin = async (email, password) => {
  const result = await firestore.collection("admin").get();
  let admin = null;

  result.docs.forEach((res) => {
    if (res.data().email === email && res.data().password === password) {
      admin = res.data();
    }
  });

  if (admin) {
    localStorage.setItem("admin", JSON.stringify(admin));
    return admin;
  } else {
    alert("Invalid Credentials");
    return null;
  }
};

export const getInstructors = async () => {
  const result = await firestore.collection("Instructors").get();
  const inst = [];
  result.docs.forEach((insructor) => {
    inst.push(insructor.data());
  });
  return inst;
};

export const changeinstructorStatus = async (status, id) => {
  await firestore.collection("Instructors").doc(id).update({ status });
};

// Advertisements
export const getAdvertisements = async () => {
  const result = await firestore.collection("Advertisements").get();
  const adds = [];
  result.docs.forEach((ad) => {
    adds.push(ad.data());
  });
  return adds;
};

export const deleteAdvertisement = async (adID) => {
  await firestore.collection("Advertisements").doc(adID).delete();
};

export const createAdvertisement = async (
  title,
  text,
  call_to_action,
  link,
  lessonCodes,
  imageurl_1,
  imageurl_2,
  imageurl_3,
  image_1,
  image_2,
  image_3,
  isEditing,
  clicks = 0,
  reach = 0,
  status = true,
  adId
) => {
  let ad_image_url_1 = imageurl_1;
  if (image_1 !== null) {
    ad_image_url_1 = await uploadImage(image_1, "/advertisements");
  }

  let ad_image_url_2 = imageurl_2;
  if (image_2 !== null) {
    ad_image_url_2 = await uploadImage(image_2, "/advertisements");
  }
  let ad_image_url_3 = imageurl_3;
  if (image_3 !== null) {
    ad_image_url_3 = await uploadImage(image_3, "/advertisements");
  }

  if (isEditing) {
    const documentRef = await firestore.collection("Advertisements").doc(adId);

    const data = {
      title,
      link,
      text,
      call_to_action,
      clicks,
      lessonCodes,
      reach,
      ad_image_url_1,
      ad_image_url_2,
      ad_image_url_3,
      status,
    };
    await documentRef.update(data);
  } else {
    const documentRef = await firestore.collection("Advertisements").doc();

    const data = {
      title,
      link,
      text,
      call_to_action,
      clicks,
      lessonCodes,
      reach,
      ad_image_url_1,
      ad_image_url_2,
      ad_image_url_3,
      status,
      advertisementId: documentRef.id,
    };
    await documentRef.set(data);
  }
};

export const updateAdvertisement = async (
  link,
  description,
  image,
  image_url,
  adId
) => {
  const documentRef = await firestore.collection("Advertisements").doc(adId);
  let adImageUrl = image_url;
  if (image !== null) {
    adImageUrl = await uploadImage(documentRef.id, image, "/advertisements");
  }
  const data = {
    link,
    description,
    adImageUrl,
  };
  await documentRef.update(data);
};

export const changeAdvertisementStatus = async (status, id) => {
  const documentRef = await firestore.collection("Advertisements").doc(id);
  await documentRef.update({ status });
};

export const submitCourse = async (
  image,
  imageUrl,
  online_link_course,
  physical_link_course,
  german_description,
  english_description
) => {
  let image_url = imageUrl;
  if (image) {
    image_url = await uploadImage(image, "Courses");
  }
  const ref = await firestore.collection("Courses").doc("course_details");
  const data = {
    image_url,
    online_link_course,
    physical_link_course,
    german_description,
    english_description,
  };
  await ref.set(data);
};

export const getCourse = async () => {
  const ref = await firestore.collection("Courses").doc("course_details").get();
  if (ref.exists) {
    return ref.data();
  }

  return null;
};
