import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { HiOutlineMail } from "react-icons/hi";
import { RiLockPasswordLine } from "react-icons/ri";
import InputWithIcon from "../Common/InputWithIcon";
import LoginButton from "./LoginButton";
import { getUser } from "./../../Actions/Auth";
import { connect } from "react-redux";
import "./Login.css";
import { forgotPassword } from "../../Firebase/Firebase";

class Login extends Component {
  state = { loading: false, email: "", password: "" };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  setLoading = () => {
    this.setState({
      loading: !this.state.loading,
    });
  };

  forgotPasswordHandler = async () => {
    const email = this.state.email;
    if (email === "") {
      alert("Please Enter valid Email.");
      return;
    }

    try {
      await forgotPassword(email);
      alert("Please check your email");
    } catch (error) {
      alert(error.message);
    }
  };

  login = async () => {
    const { email, password } = this.state;
    if (email === "" && password === "") return;
    this.setLoading();
    try {
      await this.props.getUser(email, password);
      if (Object.keys(this.props.user).length === 0) {
        this.setLoading();
        return;
      }

      if (this.props.user && !this.props.user.status) {
        this.setLoading();
        alert("Your Account is not verified from the Admin");
        return;
      }
      const { state } = this.props.location;
      this.props.history.push(state ? state.from.pathname : "/lessons");
      this.setLoading();
    } catch (error) {
      this.setLoading();
      alert(error.message);
    }
  };
  render() {
    return (
      <div className="login__background">
        <div className="login__container">
          <div>
            <div className="login__form">
              <h2> Sign In</h2>

              <InputWithIcon
                Icon={HiOutlineMail}
                name="email"
                label="Email ID"
                onChange={(e) => this.handleChange(e)}
                type="email"
                placeholder="Enter email"
              />

              <InputWithIcon
                Icon={RiLockPasswordLine}
                name="password"
                label="Password"
                onChange={(e) => this.handleChange(e)}
                type="password"
                placeholder="Enter password"
              />

              <LoginButton loading={this.state.loading} login={this.login} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, { getUser })(withRouter(Login));
