import React, { Component } from "react";
import { connect } from "react-redux";
import Input from "../../Common/Input";
import { addExercise } from "../../../Firebase/Firebase";
import { withRouter } from "react-router";

class TypeTheAnswerVisual extends Component {
  state = {
    loading: true,
    isEditing: false,
    exercise_instruction_german: "",
    exercise_instruction_english: "",
    question_text: "",
    answer_text: "",
    answer_text_variations: [],
    variations: 0,
    errors: {},
  };

  componentDidMount() {
    const isEditing = this.props.isEditing;
    const exercise = this.props.exercise;
    if (isEditing) {
      this.setState({
        exercise_instruction_english: exercise.exercise_instruction_english,
        exercise_instruction_german: exercise.exercise_instruction_german,
        question_text: exercise.question_text,
        answer_text: exercise.answer_text,
        variations: exercise.variations,
        answer_text_variations: exercise.answer_text_variations,
        isEditing,
      });
    }
  }

  exerciseInputHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  increaseVariation = () => {
    this.setState({
      variations: this.state.variations + 1,
    });
  };

  addVariationChangeHandler = (i, e) => {
    const list = [...this.state.answer_text_variations];
    list[i] = e.target.value;
    this.setState({ answer_text_variations: list });
  };

  saveExercise = async () => {
    const {
      answer_text,
      question_text,
      exercise_instruction_german,
      exercise_instruction_english,
      variations,
      answer_text_variations,
      isEditing,
    } = this.state;

    const instructor = this.props.user;
    if (
      exercise_instruction_german === "" ||
      exercise_instruction_german.trim().length === 0
    ) {
      alert("Instruction in German should be required");
      return;
    }
    if (
      exercise_instruction_english === "" ||
      exercise_instruction_english.trim().length === 0
    ) {
      alert("Instruction in English ashould be required");
      return;
    }

    if (question_text === "" || question_text.trim().length === 0) {
      alert("Question are required");
      return;
    }
    if (answer_text === "" || answer_text.trim().length === 0) {
      alert("Answer are required");
      return;
    }

    const data = {
      lessonId: this.props.lessonId,
      lesson_level: this.props.lesson_level,
      exercise_type: this.props.exercise_type,
      instructorId: instructor.uid,
      exercise_instruction_english,
      exercise_instruction_german,
      answer_text,
      question_text,
      variations,
      answer_text_variations,
      exerciseId: this.props.exercise ? this.props.exercise.exerciseId : null,
      order: 5,
    };
    this.props.setLoading(true);

    try {
      await addExercise(data, isEditing);
      this.props.history.push(`/lessons/lesson/${this.props.lessonId}`);
    } catch (error) {
      this.props.setLoading(false);

      alert(error.message);
    }
  };

  render() {
    return (
      <div>
        <Input
          name="exercise_instruction_german"
          label="Instruction (German)"
          onChange={this.exerciseInputHandler}
          value={this.state.exercise_instruction_german}
          error={this.state.errors.exercise_instruction_german}
          type="text"
          placeholder="Exercise Instruction (German)"
        />

        <Input
          name="exercise_instruction_english"
          label="Instruction (English)"
          value={this.state.exercise_instruction_english}
          onChange={this.exerciseInputHandler}
          error={this.state.errors.exercise_instruction_english}
          type="text"
          placeholder="Exercise Instruction (English)"
        />

        <Input
          name="question_text"
          label="Question"
          onChange={this.exerciseInputHandler}
          value={this.state.question_text}
          error={this.state.errors.question_text}
          type="text"
          placeholder="Question Text"
        />
        <Input
          name="answer_text"
          label="Answer"
          value={this.state.answer_text}
          onChange={this.exerciseInputHandler}
          error={this.state.errors.text_answer}
          type="text"
          placeholder="Answer Text"
        />

        {[...Array(this.state.variations)].map((x, i) => (
          <Input
            key={i}
            label="Answer Text (Variation)"
            onChange={(e) => this.addVariationChangeHandler(i, e)}
            value={this.state.answer_text_variations[i]}
            type="text"
            placeholder="Answer Variation Text"
          />
        ))}

        {/* <button
          className="btn vairation-button"
          onClick={() => this.increaseVariation()}
        >
          Add Variation
        </button> */}

        <button className="btn primary-button mt-3" onClick={this.saveExercise}>
          {this.state.isEditing ? "Save  Changes" : "Add Exercise"}
        </button>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(withRouter(TypeTheAnswerVisual));
