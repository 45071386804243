import React from "react";
import { BiSort } from "react-icons/bi";

const LessonsTable = (props) => {
  return (
    <table className="responsive__table">
      <thead>
        <tr>
          <th scope="col" onClick={() => props.raiseSort("name_german")}>
            Name (German)
            <span style={{ fontSize: "22px" }}>
              <BiSort />
            </span>
          </th>
          <th scope="col" onClick={() => props.raiseSort("name_english")}>
            Name (English)
            <span style={{ fontSize: "22px" }}>
              <BiSort />
            </span>
          </th>
          <th
            scope="col"
            onClick={() => props.raiseSort("lesson_level")}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            Level
            <span style={{ fontSize: "22px" }}>
              <BiSort />
            </span>
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {props.data.length > 0 ? (
          props.data.map((lesson) => (
            <tr key={lesson.lessonId}>
              <th scope="row" data-label="Name(German)">
                {lesson.name_german}
              </th>
              <td data-label="Name(English)">{lesson.name_english}</td>
              <td data-label="Lesson Level">{lesson.lesson_level}</td>
              <td data-label="Status">
                <div className="custom-control custom-switch">
                  <input
                    onChange={() => props.statusChangeHandler(lesson)}
                    checked={lesson.status}
                    type="checkbox"
                    className="custom-control-input"
                    id={lesson.lessonId}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor={lesson.lessonId}
                  >
                    Off/On
                  </label>
                </div>
              </td>
            </tr>
          ))
        ) : (
          <p className="mt-2">There is no lessons added yet.</p>
        )}
      </tbody>
    </table>
  );
};

export default LessonsTable;
