import React, { Component } from "react";
import ProtectedRoute from "../Common/protectedRoute";
import Excersize from "../Lessons/Excersizes";
import Profile from "../Profile/Profile";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import Lessons from "../Lessons/Lessons";
import NewLesson from "../Lessons/NewLesson";
import Login from "../Auth/Login";
import { connect } from "react-redux";
import { updateUserLogin } from "../../Actions/Auth";
import Spinner from "../Common/Spinner";
import AddExercise from "../Lessons/AddExercise";

// Admin Pages
import Instructors from "../Admin/Instructors/Instructors";
import AdminLessons from "../Admin/Lessons/Lessons";
import AdminLogin from "../Admin/Login/Login";
import Advertisements from "./../Admin/Advertiments/Advertisements";
import NewAdvertiment from "../Admin/Advertiments/NewAdvertisement";
import NewInstructor from "../Admin/Instructors/NewInstructor";
import Courses from "../Admin/Courses/Courses";
import CompleteLessonSettings from "../Admin/Lessons/CompleteLessonSetting";
import { firestore } from "../../Firebase/FirebaseConfig";
import { getDecryptedPassword } from "../../Firebase/Firebase";

class Routing extends Component {
  state = { loading: true, activeApp: "", admin: null };

  async componentDidMount() {
    const user = JSON.parse(sessionStorage.getItem("instructor"));
    // const admin = JSON.parse(localStorage.getItem("admin"));
    const activeApp = window.location.pathname;

    if (activeApp === "/admin") {
      if (user !== null && user.isAdmin) {
        this.setState({ loading: false, activeApp, admin: user });
        return;
      } else {
        this.setState({ loading: false, activeApp, admin: null });
        return;
      }
    }
    try {
      if (user !== null) {
        const result = firestore.collection("Instructors");

        result.onSnapshot((snpashot) => {
          snpashot.forEach((insructor) => {
            if (
              insructor.data().email === user.email &&
              getDecryptedPassword(insructor.data().password) ===
                getDecryptedPassword(user.password)
            ) {
              let inst = insructor.data();
              if (inst && !inst.status && window.location.pathname !== "/login") {
                console.log("Reloaded");
                window.location.replace("/login");
              }
            }
          });
        });
      }
      this.setState({ loading: false });
    } catch (error) {
      this.setState({ loading: false });
      alert(error.message);
    }
  }
  render() {
    return this.state.loading ? (
      <Spinner />
    ) : this.state.activeApp === "/admin" ? (
      this.state.admin === null ? (
        <Router>
          <Route exact path="/admin" component={AdminLogin} />
        </Router>
      ) : (
        <Router>
          <Switch>
            <Route
              exact
              path="/admin"
              render={() => <Redirect to="/admin/lessons" />}
            />

            <Route exact path="/admin/lessons" component={AdminLessons} />

            <Route
              exact
              path="/admin/lessons/completelessonsettings"
              component={CompleteLessonSettings}
            />
            <Route
              exact
              path="/admin/lessons/completelessonsettings"
              component={CompleteLessonSettings}
            />
            <Route exact path="/admin/instructors" component={Instructors} />
            <Route
              exact
              path="/admin/instructors/new"
              component={NewInstructor}
            />
            <Route
              exact
              path="/admin/advertisements"
              component={Advertisements}
            />
            <Route
              exact
              path="/admin/advertisements/new"
              component={NewAdvertiment}
            />
            <Route exact path="/admin/courses" component={Courses} />
          </Switch>
        </Router>
      )
    ) : (
      <Router>
        <Switch>
          <Route
            exact
            path="/"
            render={() => {
              return this.props.isAuthenticated ? (
                <Redirect to="/lessons" />
              ) : (
                <Redirect to="/login" />
              );
            }}
          />
          <Route exact path="/login" component={Login} />

          <ProtectedRoute exact path="/lessons" component={Lessons} />
          <ProtectedRoute
            exact
            path="/lessons/lesson/:lessonId"
            component={Excersize}
          />
          <ProtectedRoute
            exact
            path="/lessons/addExercise"
            component={AddExercise}
          />
          <ProtectedRoute
            exact
            path="/lessons/create-new-lesson"
            component={NewLesson}
          />
          <ProtectedRoute exact path="/profile" component={Profile} />
        </Switch>
      </Router>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { updateUserLogin })(Routing);
