import React, { Component } from "react";
import Sidebar from "../Sidebar/Sidebar";
import Spinner from "../Common/Spinner";
import {
  getExercises,
  deleteExercise,
  getLesson,
} from "../../Firebase/Firebase";
import { MdAdd } from "react-icons/md";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";

class Excersize extends Component {
  state = {
    loading: true,
    prevExcersizes: [],
    exercise: null,
    updateInDb: true,
    lessonId: "",
    lesson: null,
    errors: {},
  };

  async componentDidMount() {
    try {
      const lessonId = this.props.match.params.lessonId;
      const lesson = await getLesson(lessonId);
      const prevExcersizes = await getExercises(lessonId);
      this.setState({ lesson, lessonId, prevExcersizes, loading: false });
    } catch (error) {
      this.setState({ loading: false });
      alert(error.message);
    }
  }

  fileInputHandler = (e) => {
    this.setState({ [e.target.name]: e.target.files[0] });
  };

  exerciseTypeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  exerciseInputHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  deleteExercise = async (exercise, updateindb) => {
    const confirm = window.confirm("Are you sure you want to delete this?");
    if (confirm) {
      try {
        if (updateindb) {
          this.setState({ loading: true });
          const prevExcersizes = [...this.state.prevExcersizes];
          const updatePrevExer = prevExcersizes.filter(
            (exer) => exer.exerciseId !== exercise.exerciseId
          );
          await deleteExercise(exercise.exerciseId);
          this.setState({ prevExcersizes: updatePrevExer, loading: false });
        } else {
          this.setState({ loading: true });
          const prevExcersizes = [...this.state.newExcersizes];
          const updatePrevExer = prevExcersizes.filter(
            (exer) => exer.exerciseId !== exercise.exerciseId
          );
          this.setState({ newExcersizes: updatePrevExer, loading: false });
        }
      } catch (error) {
        this.setState({ loading: false });
        alert(error.message);
      }
    }
  };

  updateExercise = async (exercise, updateInDb) => {
    const lessonId = this.state.lessonId;
    this.props.history.push({
      pathname: "/lessons/addExercise",
      state: { data: true, exercise, lessonId },
    });
  };

  editingHandler = () => {
    const isEditing = this.state.isEditing;
    if (isEditing) {
      this.clearState();
      this.setState({ isEditing: false });
    }
  };

  render() {
    const sortedExercies = this.state.prevExcersizes.sort(function (a, b) {
      return a.order - b.order;
    });

    return (
      <Sidebar>
        {this.state.loading ? (
          <Spinner />
        ) : (
          <div>
            <h1 className="mb-2">Exercises</h1>
            <h5 className="mb-4">Name: {this.state.lesson.name_german}</h5>
            <table class="responsive__table">
              <thead>
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Exercise Type</th>
                  <th scope="col">Lesson Level</th>
                  <th scope="col"></th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {this.state.prevExcersizes.length > 0 ? (
                  sortedExercies.map((exercise) => (
                    <tr key={exercise.exerciseId}>
                      <th data-label="ID">
                        {exercise.exerciseId.substr(0, 5)}
                      </th>
                      <td data-label="Type">{exercise.exercise_type}</td>
                      <td data-label="Lesson Level">{exercise.lesson_level}</td>
                      <td data-label="Update">
                        <button
                          className="btn primary-button"
                          onClick={() => this.updateExercise(exercise, true)}
                        >
                          Update
                        </button>
                      </td>
                      <td data-label="Delete">
                        <button
                          className="btn btn-danger"
                          onClick={() => this.deleteExercise(exercise, true)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <p className="mt-2">There is no exercises added yet.</p>
                )}
              </tbody>
            </table>

            <Link
              to={{
                pathname: "/lessons/addExercise",
                state: {
                  data: false,
                  exercise: null,
                  lessonId: this.state.lessonId,
                },
              }}
              className="btn btn-warning px-3 fs-20 floating-button"
            >
              <MdAdd />
            </Link>
          </div>
        )}
      </Sidebar>
    );
  }
}

export default withRouter(Excersize);
