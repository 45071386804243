import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { addSpeakingExercise } from "../../../Firebase/Firebase";
import Input from "../../Common/Input";
import Spinner from "../../Common/Spinner";
import VoiceRecorder from "../../Common/VoiceRecorder";
import Modal from "./../../Common/Modal";
import { FaMicrophone } from "react-icons/fa";

class Speaking extends Component {
  state = {
    loading: true,
    isEditing: false,
    exercise_instruction_german: "",
    exercise_instruction_english: "",
    question_audio_file: null,
    answer_audio_file: null,
    question_audio_url: null,
    answer_audio_url: null,
    selectedFileType: null,
    question_text: "",
    answer_text: "",
    errors: {},
  };
  componentDidMount() {
    const isEditing = this.props.isEditing;
    const exercise = this.props.exercise;
    if (isEditing) {
      this.setState({
        exercise_instruction_english: exercise.exercise_instruction_english,
        exercise_instruction_german: exercise.exercise_instruction_german,
        question_audio_url: exercise.question_audio_file,
        answer_audio_url: exercise.answer_audio_file,
        question_text: exercise.question_text,
        answer_text: exercise.answer_text,
        isEditing,
      });
    }

    this.setState({ loading: false });
  }
  exerciseInputHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  fileInputHandler = (e) => {
    this.setState({ [e.target.name]: e.target.files[0] });
  };

  handleFile = (file) => {
    const selectedFileType = this.state.selectedFileType;
    this.setState({ [selectedFileType]: file });
    alert("Uploaded");
  };

  saveExercise = async () => {
    const {
      question_audio_file,
      answer_audio_url,
      answer_audio_file,
      question_audio_url,
      exercise_instruction_german,
      isEditing,
      exercise_instruction_english,
      question_text,
      answer_text,
    } = this.state;
    const instructor = this.props.user;
    if (
      exercise_instruction_german === "" ||
      exercise_instruction_german.trim().length === 0
    ) {
      alert("Instruction in German should be required");
      return;
    }
    if (
      exercise_instruction_english === "" ||
      exercise_instruction_english.trim().length === 0
    ) {
      alert("Instruction in English ashould be required");
      return;
    }

    if (question_text === "") {
      alert("Question Text are required");
      return;
    }
    if (answer_text === "") {
      alert("Answer Text required");
      return;
    }

    if (!isEditing) {
      if (question_audio_file === null) {
        alert("Question Audio are required");
        return;
      }
      if (answer_audio_file === null) {
        alert("Answer Audio are required");
        return;
      }
    }

    const data = {
      lessonId: this.props.lessonId,
      lesson_level: this.props.lesson_level,
      exercise_type: this.props.exercise_type,
      instructorId: instructor.uid,
      exercise_instruction_english,
      exercise_instruction_german,
      exerciseId: this.props.exercise ? this.props.exercise.exerciseId : null,
      question_audio_file,
      question_audio_url,
      answer_audio_file,
      answer_audio_url,
      question_text,
      answer_text,
      order: 7,
    };

    this.props.setLoading(true);

    try {
      if (isEditing) {
        await addSpeakingExercise(data, isEditing);
        this.props.history.push(`/lessons/lesson/${this.props.lessonId}`);
      } else {
        await addSpeakingExercise(data, isEditing);
        this.props.history.push(`/lessons/lesson/${this.props.lessonId}`);
      }
    } catch (error) {
      this.props.setLoading(false);

      alert(error.message);
    }
  };

  render() {
    return this.state.loading ? (
      <Spinner />
    ) : (
      <div>
        <Input
          name="exercise_instruction_german"
          label="Instruction (German)"
          onChange={this.exerciseInputHandler}
          value={this.state.exercise_instruction_german}
          error={this.state.errors.exercise_instruction_german}
          type="text"
          placeholder="Exercise Instruction (German)"
        />

        <Input
          name="exercise_instruction_english"
          label="Instruction (English)"
          value={this.state.exercise_instruction_english}
          onChange={this.exerciseInputHandler}
          error={this.state.errors.exercise_instruction_english}
          type="text"
          placeholder="Exercise Instruction (English)"
        />

        <div>
          <div className="row mb-2">
            <div className="col-sm-7">
              <div className="row">
                <div className="col-6">
                  <label className="font-weight-bold mt-1">
                    Question Audio
                  </label>
                </div>
                <div className="col-4">
                  <div>
                    <input
                      name="question_audio_file"
                      type="file"
                      accept="audio/*"
                      onChange={this.fileInputHandler}
                      className="form-check-input"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              <audio
                controls
                src={
                  this.state.question_audio_file
                    ? URL.createObjectURL(this.state.question_audio_file)
                    : this.state.question_audio_url
                    ? this.state.question_audio_url
                    : null
                }
              >
                Your browser does not support the audio element.
              </audio>
            </div>

            <div className="col-sm-1 d-flex align-items-center">
              <button
                className="btn "
                data-toggle="modal"
                data-target="#paymentmodal"
                onClick={() =>
                  this.setState({ selectedFileType: "question_audio_file" })
                }
              >
                <span className="ml-1 fs-20">
                  <FaMicrophone />
                </span>
              </button>
            </div>
          </div>
          <Input
            name="question_text"
            label="Question"
            onChange={this.exerciseInputHandler}
            value={this.state.question_text}
            error={this.state.errors.question_text}
            type="text"
            placeholder="Question Text"
          />

          <div className="row mb-2">
            <div className="col-sm-7">
              <div className="row">
                <div className="col-6">
                  <label className="font-weight-bold mt-1">Answer Audio</label>
                </div>
                <div className="col-4">
                  <div>
                    <input
                      name="answer_audio_file"
                      type="file"
                      accept="audio/*"
                      onChange={this.fileInputHandler}
                      className="form-check-input"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              <audio
                controls
                src={
                  this.state.answer_audio_file
                    ? URL.createObjectURL(this.state.answer_audio_file)
                    : this.state.answer_audio_url
                    ? this.state.answer_audio_url
                    : null
                }
              >
                Your browser does not support the audio element.
              </audio>
            </div>

            <div className="col-sm-1 ">
              <button
                className="btn "
                data-toggle="modal"
                data-target="#paymentmodal"
                onClick={() =>
                  this.setState({ selectedFileType: "answer_audio_file" })
                }
              >
                <span className="ml-1 fs-20">
                  <FaMicrophone />
                </span>
              </button>
            </div>
          </div>

          <Input
            name="answer_text"
            label="Answer"
            value={this.state.answer_text}
            onChange={this.exerciseInputHandler}
            error={this.state.errors.text_answer}
            type="text"
            placeholder="Answer Text"
          />
        </div>

        <button className="btn primary-button mt-3" onClick={this.saveExercise}>
          {this.state.isEditing ? "Save  Changes" : "Add Exercise"}
        </button>

        <Modal
          label="Record Voice"
          modal_size_class="modal-lg"
          buttonClicked={this.addVoice}
        >
          <VoiceRecorder handleFile={this.handleFile} />
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(withRouter(Speaking));
