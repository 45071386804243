import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { getLessons, changelessonStatus } from "../../../Firebase/Firebase";
import Spinner from "../../Common/Spinner";
import Sidebar from "../Sidebar/Sidebar";
import LessonsTable from "./LessonsTable";
import _ from "lodash";

// desc asc
class Lessons extends Component {
  state = {
    lessons: [],
    loading: true,
    sortColoumn: { path: "lesson_level", order: "asc" },
  };
  async componentDidMount() {
    try {
      const lessons = await getLessons();
      this.setState({ loading: false, lessons });
    } catch (error) {
      this.setState({ loading: false });
      alert(error.message);
    }
  }

  statusHandler = async (lesson) => {
    if (lesson.exercise_types.length < 7) {
      alert(
        `You cannot turn on the lesson ${lesson.name_english} because this lesson does not have all 7 exercises`
      );
      return;
    }

    const response = window.confirm(
      !lesson.status
        ? "Are you sure you want to turn the lesson on?"
        : "Are you sure you want to  turn the lesson off?"
    );
    if (response) {
      try {
        this.setState({ loading: true });
        await changelessonStatus(!lesson.status, lesson.lessonId);
        const lessons = [...this.state.lessons];
        const updatedlessons = [];
        lessons.forEach((item) => {
          if (item.id === lesson.id) {
            item.status = !item.status;
          }
          updatedlessons.push(item);
        });
        this.setState({
          lessons: updatedlessons,
          loading: false,
        });
      } catch (error) {
        this.setState({ loading: false });
        alert(error.message);
      }
    }
  };

  raiseSort = (path) => {
    let sortColoumn = { ...this.state.sortColoumn };
    sortColoumn.path = path;
    if (sortColoumn.order === "asc") {
      sortColoumn.order = "desc";
    } else {
      sortColoumn.order = "asc";
    }
    this.setState({ sortColoumn });
  };

  render() {
    const { lessons, sortColoumn } = this.state;
    const sortedLessons = _.orderBy(
      lessons,
      [sortColoumn.path],
      [sortColoumn.order]
    );

    return (
      <Sidebar>
        {this.state.loading ? (
          <Spinner />
        ) : (
          <div>
            <div className="d-flex justify-content-between mt-2 mb-5">
              <h2>Lessons</h2>
              <button
                onClick={() =>
                  this.props.history.push(
                    "/admin/lessons/completelessonsettings"
                  )
                }
                className="btn primary-button-outline "
              >
                Complete Lesson Settings
              </button>
            </div>
            <LessonsTable
              data={sortedLessons}
              statusChangeHandler={this.statusHandler}
              raiseSort={this.raiseSort}
            />
          </div>
        )}
      </Sidebar>
    );
  }
}

export default withRouter(Lessons);
